import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Alert,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Button,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_ACCOUNT, PATH_AUTH, PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
//
import { MIconButton } from '../../@material-extend';

// ----------------------------------------------------------------------

LoginForm.propTypes = {
  email: PropTypes.string,
  handleNext: PropTypes.func,
  afterSucces: PropTypes.func
};

export default function LoginForm({ email, handleNext, afterSucces, passReset }) {
  const auth = useAuth();
  const { login, revalidate } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const isAdminOrTeacher = auth.hasRoles(['admin', 'teacher']);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email moet een geldige email adres zijn').required('Email is verplicht'),
    password: Yup.string().required('Wachtwoord is verplicht')
  });

  const formik = useFormik({
    initialValues: {
      email: email ?? '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        const response = await login(values.email, values.password);
        if (response?.errors) {
          setErrors({ ...response.errors });
        } else {
          const login = await revalidate();
          if (login === '2fa') {
            return;
          }
          if (login) {
            if (typeof afterSucces === 'function') {
              afterSucces();
              return;
            }
            if (isAdminOrTeacher) {
              navigate(`${PATH_DASHBOARD.general.dashboard}`);
              return;
            }
            navigate(`${PATH_ACCOUNT.general.orders}`);
          }
        }
        enqueueSnackbar(response?.errors ? response?.message : 'Login success', {
          variant: response?.errors ? 'error' : 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        console.error('error', error);
        resetForm();
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ ...error.errors, afterSubmit: error.message });
        }
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="E-mailadres"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Wachtwoord"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        {typeof handleNext === 'function' ? (
          <>
            <Typography variant="caption" textAlign="center" width="100%">
              Heb je nog geen account klik dan op 'verder zonder account' nadat wij je inschrijving hebben ontvangen
              zullen we een account voor je aanmaken.
            </Typography>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 3, sm: 2 }}
              justifyContent="space-between"
              sx={{ my: 2 }}
            >
              {passReset && passReset === 'account' ? (
                <Button
                  href={`${window.lcSubscribe?.accountUrl ?? 'https://account.learncare.nl'}/auth/reset-password`}
                  variant="text"
                  size="small"
                  style={{ textTransform: 'none' }}
                >
                  Wachtwoord vergeten?
                </Button>
              ) : (
                <Button
                  component={RouterLink}
                  variant="text"
                  size="small"
                  to={PATH_AUTH.resetPassword}
                  style={{ textTransform: 'none' }}
                >
                  Wachtwoord vergeten?
                </Button>
              )}

              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                Inloggen
              </LoadingButton>

              <Button
                variant="text"
                color="inherit"
                onClick={handleNext}
                style={{ marginRight: '1rem', textTransform: 'none' }}
              >
                Verder zonder account
              </Button>
            </Stack>
          </>
        ) : (
          <>
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
              <FormControlLabel
                control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
                label="Gegevens onthouden"
              />

              <Link
                component={RouterLink}
                variant="subtitle2"
                to={PATH_AUTH.resetPassword}
                style={{ textTransform: 'none' }}
              >
                Wachtwoord vergeten?
              </Link>
            </Stack>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              style={{ textTransform: 'none' }}
            >
              Login
            </LoadingButton>
          </>
        )}
      </Form>
    </FormikProvider>
  );
}
