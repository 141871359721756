import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Typography, Button, Card, Stack, Alert } from '@mui/material';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import CoCartAPI from '@cocart/cocart-rest-api';
import { LoginForm } from '../authentication/login';
import ParticipantForm from './ParticipantForm';
import InvoiceDetailsForm from './InvoiceDetailsForm';
import useAuth from '../../hooks/useAuth';
import { getFullName } from '../../utils/contact';
import ReviewOrder from './ReviewOrder';
import LoadingScreen from '../LoadingScreen';
import { coCartApiActions } from './co-cart-api';
import { ResetPasswordForm } from '../authentication/reset-password';

const CoCart = new CoCartAPI({
  url: window.lcSubscribe?.cartUrl || 'https://learncare.test',
  version: 'cocart/v2'
  // consumerKey: 'ck_cfcfd18fcf965ac31c294a1ff1ab4537e1941799',
  // consumerSecret: 'cs_a25a67996ddff3c8b3286c249ab8e5f4a61b83e6'
});

const steps = ['Account', 'Deelnemer', 'Facturatie', 'Bestelling'];

const SubscribeFormStepper = () => {
  const [contact, setContact] = useState({});
  const [company, setCompany] = useState({});
  const [activeStep, setActiveStep] = React.useState(0);
  const [cart, setCart] = useState(null);
  const [errors, setErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const auth = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    if (auth.isAuthenticated && auth?.user?.contactDetails) {
      setContact(auth?.user?.contactDetails);
    }

    if (auth.isAuthenticated && auth?.user?.companyDetails) {
      setCompany(auth?.user?.companyDetails);
    } else {
      setCompany({ invoiceType: 'private' });
    }

    // if (auth.isAuthenticated && activeStep === 0) {
    //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // }
  }, [auth]);

  useEffect(() => {
    setIsLoading(true);

    if (!window?.lcSubscribe?.key) {
      setCart(false);
    }

    dispatch(
      coCartApiActions(
        'get',
        '',
        (response) => {
          console.log('get res', response);
          setIsLoading(false);
          if (response.cart_hash) {
            setCart(response);
          }
        },
        (error) => {
          console.log('get error', error);
          setErrors('We kunnen je winkelwagen niet ophalen, probeer het later opnieuw of stuur ons een email.');
          setCart(false);
          setIsLoading(false);
        }
      )
    );
  }, []);

  const contactNextHandler = (contact, cart) => {
    console.log(contact);
    setContact({ ...contact });
    setCart(cart);
    handleNext();
  };

  const invoiceNextHandler = (invoiceDetails, cart) => {
    if (invoiceDetails) {
      setCompany({ ...invoiceDetails });
      setCart(cart);
    }
    handleNext();
  };

  const setLoading = () => {
    setIsLoading(!isLoading);
  };

  const refreshOrderDetails = (refreshedCart) => {
    const newCart = { ...refreshedCart, customer: { ...cart.customer } };
    console.log(newCart);
    setCart(newCart);
  };

  const isStepOptional = (step) => step === false;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  if (isLoading) {
    return (
      <div style={{ maxWidth: '758px', margin: '0 auto' }}>
        <Card sx={{ p: 3, textAlign: 'center', my: 4 }}>
          <Box justifyContent="center" alignItems="center">
            <LoadingScreen sx={{ p: 3, textAlign: 'center', my: 4 }} />
          </Box>
        </Card>
      </div>
    );
  }

  if (cart === false) {
    return (
      <div style={{ maxWidth: '758px', margin: '0 auto' }}>
        <Card sx={{ p: 3, textAlign: 'center', my: 4 }}>
          <Typography variant="h4" textAlign="center">
            {errors ? `Er is iets fout gegaan` : `Je winkelwagen is leeg`}
          </Typography>
          {errors && (
            <>
              <Alert severity="error">{errors}</Alert>
              <Box sx={{ my: 2 }}>
                <Button variant="contained" href="https//www.learncare.nl/contact">
                  Contact
                </Button>
              </Box>
            </>
          )}
          {!errors && (
            <>
              <Typography variant="body" textAlign="center">
                Bekijk ons aanbod om te blijven ontwikkelen
              </Typography>
              <Box sx={{ my: 2 }}>
                <Button variant="contained" href="/aanbod">
                  Bekijk aanbod
                </Button>
              </Box>
            </>
          )}
        </Card>
      </div>
    );
  }

  return (
    <div style={{ maxWidth: '758px', margin: '0 auto' }}>
      <Stepper activeStep={activeStep} sx={{ my: 4 }}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = <Typography variant="caption">Optional</Typography>;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {auth.isAuthenticated && activeStep === 0 && (
        <Card sx={{ p: 3 }}>
          <Typography variant="h4" textAlign="center">
            Account
          </Typography>
          <Typography variant="body" textAlign="center">
            {`Je bent ingelogt als ${getFullName(auth.user.contactDetails)} klik op verder gaan.`}
          </Typography>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 3, sm: 2 }}
            justifyContent="space-between"
            sx={{ my: 2 }}
          >
            <Button
              variant="text"
              color="error"
              onClick={() => {
                auth.logout(true);
              }}
              style={{ marginRight: '1rem' }}
            >
              Uitloggen
            </Button>
            <Button type="button" variant="contained" onClick={handleNext}>
              Verder
            </Button>
          </Stack>
        </Card>
      )}
      {!auth.isAuthenticated && activeStep === 0 && (
        <Card sx={{ p: 3 }}>
          <LoginForm afterSucces={handleNext} handleNext={handleNext} passReset="account" />
        </Card>
      )}
      {activeStep === 1 && (
        <ParticipantForm cart={cart} contact={contact} onNextHandler={contactNextHandler} handleBack={handleBack} />
      )}
      {activeStep === 2 && (
        <InvoiceDetailsForm
          cart={cart}
          invoiceDetails={company}
          onNextHandler={invoiceNextHandler}
          handleBack={handleBack}
        />
      )}
      {activeStep === 3 && (
        <ReviewOrder
          cart={cart}
          contact={contact}
          company={company}
          refreshOrderDetailsHandler={refreshOrderDetails}
          setLoadingScreen={setLoading}
          handleBack={handleBack}
        />
      )}
    </div>
  );
};

export default SubscribeFormStepper;
