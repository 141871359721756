// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_ACCOUNTS = '/account';

// ----------------------------------------------------------------------
export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  createNewPassword: path(ROOTS_AUTH, '/password-reset'),
  verify: path(ROOTS_AUTH, '/verify'),
  verify2fa: path(ROOTS_AUTH, '/verify-2fa'),
  verifyIp: path(ROOTS_AUTH, '/verify-ip')
};

export const PATH_ACCOUNT = {
  root: ROOTS_ACCOUNTS,
  general: {
    orders: path(ROOTS_ACCOUNTS, '/bestellingen'),
    invoices: path(ROOTS_ACCOUNTS, '/facturen'),
    settings: path(ROOTS_ACCOUNTS, '/gegevens')
  }
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    dashboard: path(ROOTS_DASHBOARD, '/overzicht'),
    exporter: path(ROOTS_DASHBOARD, '/exporteren'),
    settings: path(ROOTS_DASHBOARD, '/instellingen'),
    twoFactorConfig: path(ROOTS_DASHBOARD, '/instellingen/2fa'),
    tasks: path(ROOTS_DASHBOARD, '/taken'),
    orders: path(ROOTS_DASHBOARD, '/orders'),
    order: {
      root: path(ROOTS_DASHBOARD, '/order'),
      details: path(ROOTS_DASHBOARD, '/order/details'),
      newOrder: path(ROOTS_DASHBOARD, '/order/nieuw'),
      edit: path(ROOTS_DASHBOARD, '/order/bewerken')
    },
    products: {
      root: path(ROOTS_DASHBOARD, '/producten'),
      connect: path(ROOTS_DASHBOARD, '/producten/connect'),
      descriptions: path(ROOTS_DASHBOARD, '/producten/inhoud')
    },
    product: {
      root: path(ROOTS_DASHBOARD, '/product'),
      details: path(ROOTS_DASHBOARD, '/product/details'),
      newProduct: path(ROOTS_DASHBOARD, '/product/nieuw')
    },
    schedules: path(ROOTS_DASHBOARD, '/planning'),
    companies: path(ROOTS_DASHBOARD, '/bedrijven'),
    company: {
      root: path(ROOTS_DASHBOARD, '/bedrijf'),
      details: path(ROOTS_DASHBOARD, '/bedrijf/details'),
      newCompany: path(ROOTS_DASHBOARD, '/bedrijf/nieuw')
    },
    contacts: path(ROOTS_DASHBOARD, '/contacten'),
    contact: {
      root: path(ROOTS_DASHBOARD, '/contact'),
      details: path(ROOTS_DASHBOARD, '/contact/details'),
      newContact: path(ROOTS_DASHBOARD, '/contact/nieuw')
    },
    invoices: {
      root: path(ROOTS_DASHBOARD, '/facturen'),
      bulk_invoices: path(ROOTS_DASHBOARD, '/facturen/verzamelfacturering')
    }
  }
};
