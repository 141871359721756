import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import { Link as RouterLink, Navigate, useNavigate, useParams } from 'react-router-dom';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
// material
import { styled } from '@mui/material/styles';
import { Box, Button, Container, Typography } from '@mui/material';
// layouts
import LogoOnlyLayout from '../../layouts/LogoOnlyLayout';
// routes
import { PATH_ACCOUNT, PATH_AUTH, PATH_DASHBOARD } from '../../routes/paths';
// components
import Page from '../../components/Page';

import LoadingScreen from '../../components/LoadingScreen';
import { verifyIP, sendVerifyIpEmail } from '../../redux/api-actions';
import { useDispatch } from '../../redux/store';
import useAuth from '../../hooks/useAuth';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function VerifyIP() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [verifiedEmailSend, setVerifiedEmailSend] = useState(false);
  const [verified, setVerified] = useState(false);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  // get expired and signature from url $_GET param and set to state
  const userId = new URLSearchParams(window.location.search).get('user')?.replace(/[^a-zA-Z0-9]/g, '');
  const expires = new URLSearchParams(window.location.search).get('expires')?.replace(/[^a-zA-Z0-9]/g, '');
  const signature = new URLSearchParams(window.location.search).get('signature')?.replace(/[^a-zA-Z0-9]/g, '');

  const sendVerifyIPEmailHandler = () => {
    dispatch(
      sendVerifyIpEmail(
        (data) => {
          console.log(data.code === 'Verified email send');
          if (data?.code === 'resent_unverified_ip') {
            setVerifiedEmailSend(true);
          }
          if (data?.code === 'verified_ip') {
            setVerified(true);
            setError(false);
            auth.revalidate();
          }
        },
        (error) => {
          if (error?.message === 'We send you a email') {
            setVerifiedEmailSend(true);
            setError(false);
          } else {
            setError(error?.message ?? 'Er is iets fout gegaan');
          }
        }
      )
    );
  };

  const verifyIPHandler = () => {
    setIsLoading(true);
    dispatch(
      verifyIP(
        userId,
        expires,
        signature,
        (data) => {
          if (!data?.data.error && data?.data?.code === 'verified_ip') {
            setVerified(true);
            setError(false);
            auth.revalidate();
          }
          setIsLoading(false);
        },
        (error) => {
          setError(error?.message ?? 'Er is iets fout gegaan');
          setIsLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    if (!auth.isInitialized) {
      return;
    }

    if (auth.isAuthenticated && auth?.user?.id) {
      setVerified(true);
      setError(false);
      return;
    }

    if (!auth.isAuthenticated && !auth.needIpCheck) {
      navigate(PATH_AUTH.login, { replace: true });
    }

    if (!signature || !userId || !expires) {
      setError('Je ip is nog niet geverifieerd, we hebben je een email gestuurd met een link om je ip te verifiëren.');
    } else if (expires < Math.floor(Date.now() / 1000)) {
      // check if expires is not expired
      sendVerifyIPEmailHandler();
    } else {
      verifyIPHandler();
    }
  }, [signature, userId, expires, auth, auth.isInitialized]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  let title = 'Ip Adres verificatie nodig';
  if (verified) {
    title = 'Ip Adres verificatie is gelukt';
  }

  if (expires < Math.floor(Date.now() / 1000)) {
    title = 'Ip Adres verificatie is verlopen,  we sturen je een nieuwe email';
  }

  return (
    <RootStyle title="Verify">
      <LogoOnlyLayout />

      <Container>
        <Box sx={{ maxWidth: 480, mx: 'auto' }}>
          <Button
            size="small"
            component={RouterLink}
            to={PATH_AUTH.login}
            startIcon={<Icon icon={arrowIosBackFill} width={20} height={20} />}
            sx={{ mb: 3 }}
          >
            Back
          </Button>

          <Typography variant="h3" paragraph>
            {title}
          </Typography>

          {verified && !error && (
            <Button
              variant="text"
              component={RouterLink}
              sx={{ cursor: 'pointer', textTransform: 'none' }}
              underline="none"
              to={
                auth.hasRoles(['admin', 'coach', 'teacher'])
                  ? PATH_DASHBOARD.general.dashboard
                  : PATH_ACCOUNT.general.dashboard
              }
            >
              Naar dashboard
            </Button>
          )}

          {error && !verifiedEmailSend && (
            <>
              <Typography variant="body2" align="left" sx={{ paddingBottom: 2 }}>
                {error}
              </Typography>
              <Button
                variant="text"
                sx={{ cursor: 'pointer', textTransform: 'none' }}
                underline="none"
                onClick={sendVerifyIPEmailHandler}
              >
                Email niet ontvangen? Klik hier om de email opnieuw te versturen.
              </Button>
            </>
          )}

          {verifiedEmailSend && (
            <>
              <Typography variant="body2" align="left" sx={{ paddingBottom: 2 }}>
                Bekijk je email en volg de instructies om je ip te valideren.
              </Typography>
              <Button
                variant="text"
                sx={{ cursor: 'pointer', textTransform: 'none' }}
                underline="none"
                onClick={sendVerifyIPEmailHandler}
              >
                Email niet ontvangen? Klik hier om de email opnieuw te versturen.
              </Button>
            </>
          )}
        </Box>
      </Container>
    </RootStyle>
  );
}
