import { faShoppingCart, faProjectDiagram, faHandHoldingMedical } from '@fortawesome/pro-regular-svg-icons';

export const platformSettingsConfig = {
  budgetcoach: {
    name: 'Budgetcoach.nl',
    logo: <img src="/static/brand/logo-budgetcoach.svg" alt="Logo Budgetcoach" />,
    palette: {
      primary: {
        lighter: '#9cd8c0',
        light: '#27b382',
        main: '#22a476',
        dark: '#1d9269',
        darker: '#1b6045',
        contrastText: '#fff'
      },
      secondary: {
        lighter: '#D6E4FF',
        light: '#84A9FF',
        main: '#3366FF',
        dark: '#1939B7',
        darker: '#091A7A',
        contrastText: '#fff'
      }
    },
    features: {
      orders: {
        title: 'Inschrijvingen',
        singularName: 'Inschrijving',
        icon: faHandHoldingMedical
      },
      products: {
        title: 'Trajecten',
        singularName: 'Traject',
        icon: faProjectDiagram,
        examOption: false,
        materialsOption: false,
        companies: true,
        contactsInOverview: false
      },
      defaultPrivacy: {
        teachers: 'closed',
        managers: 'closed'
      },
      coaches: true,
      teachers: false,
      locations: false,
      edudex: false,
      anewSpring: false,
      bloomville: false,
      woocommerce: false,
      branchOrganisations: false,
      bulkmail: false,
      certificates: false
    }
  },
  learncare: {
    name: 'Learncare Academy',
    logo: <img src="/static/brand/logo-learncare-academy.png" alt="Logo Learncare Academy" />,
    palette: {
      primary: {
        lighter: '#C8FACD',
        light: '#ffc346',
        main: '#f39201',
        dark: '#ba6400',
        darker: '#005249',
        contrastText: '#fff'
      },
      secondary: {
        lighter: '#D6E4FF',
        light: '#84A9FF',
        main: '#3366FF',
        dark: '#1939B7',
        darker: '#091A7A',
        contrastText: '#fff'
      }
    },
    features: {
      orders: {
        title: 'Inschrijvingen',
        singularName: 'Inschrijving',
        icon: faShoppingCart
      },
      products: {
        title: 'Opleidingen',
        singularName: 'Opleiding',
        icon: faProjectDiagram,
        examOption: false,
        materialsOption: true,
        companies: false,
        contactsInOverview: true
      },
      defaultPrivacy: {
        teachers: 'open',
        managers: 'open'
      },
      coaches: false,
      teachers: true,
      locations: true,
      edudex: true,
      anewSpring: true,
      bloomville: true,
      woocommerce: true,
      branchOrganisations: true,
      bulkmail: true,
      certificates: true
    }
  }
};

export const platformSettings = platformSettingsConfig[process.env.REACT_APP_SETTINGS_FOR];

export const sanctumConfig = {
  baseUrl:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_API_URL_PRODUCTION
      : process.env.REACT_APP_API_URL_DEVELOPMENT,
  apiSecret: process.env.REACT_APP_API_SECRET,
  frontUrl: process.env.LMS_FRONT_URL
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export const cognitoConfig = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID
};

export const auth0Config = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const mapConfig = process.env.REACT_APP_MAP_MAPBOX;

export const googleAnalyticsConfig = process.env.REACT_APP_GA_MEASUREMENT_ID;
