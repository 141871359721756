export const getDate = (date, time = false, db = false) => {
  if (!date) {
    return 'Onbekend';
  }

  const dateObj = new Date(date);

  if (!dateObj) {
    return 'Onbekend';
  }

  if (time) {
    return dateObj.toLocaleString('nl-NL', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour12: false,
      hour: '2-digit',
      minute: '2-digit'
    });
  }

  const day = dateObj.toLocaleString('nl-NL', { day: '2-digit' });
  const month = dateObj.toLocaleString('nl-NL', { month: '2-digit' });
  const year = dateObj.getFullYear();

  if (db) {
    return `${year}-${month}-${day}`;
  }

  return `${day}-${month}-${year}`;
};
