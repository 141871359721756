import { createSlice } from '@reduxjs/toolkit';
// utils
import { defaultInitialReducers, defaultInitialState } from '../defaults';

// ----------------------------------------------------------------------

const slice = createSlice({
  name: 'comment',
  initialState: {
    ...defaultInitialState,
    orderBy: 'created_at',
    order: 'desc',
    perPage: 25,
    isOpen: false,
    selectSchedule: {}
  },
  reducers: {
    ...defaultInitialReducers,
    setCurrentComments(state, action) {
      state.items = [...action.payload];
    },
    afterSaveSuccess(state, action) {
      state.items.push(action.payload);
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const commentActions = slice.actions;
