import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import { numbToEuro } from '../../utils/currency';
import { coCartApiActions } from './co-cart-api';

const Coupon = ({ coupon, refreshOrderDetailsHandler }) => {
  const { couponCode } = coupon.coupon;
  const [removingCoupon, setRemovingCoupon] = useState(false);
  const dispatch = useDispatch();
  console.log(couponCode, coupon);
  const removeCoupon = () => {
    setRemovingCoupon(true);

    dispatch(
      coCartApiActions(
        'remove',
        coupon?.coupon,
        (response) => {
          console.log('Coupon', response);
          setRemovingCoupon(false);
          if (response.cart_hash) {
            refreshOrderDetailsHandler(response);
          }
        },
        (error) => {
          console.log('Coupon', error);
          setRemovingCoupon(false);
        }
      )
    );
  };

  return (
    <tr className="cart-discount coupon-summerelearning-25">
      <th>
        {coupon.label}{' '}
        <LoadingButton variant="text" size="small" color="error" loading={removingCoupon} onClick={removeCoupon}>
          Verwijderen
        </LoadingButton>
      </th>
      <td>
        -<span className="woocommerce-Price-amount amount">{numbToEuro(coupon.saving / 100)}</span>{' '}
      </td>
    </tr>
  );
};

Coupon.propTypes = {
  coupon: PropTypes.object
};

export default Coupon;
