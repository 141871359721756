import CoCartAPI from '@cocart/cocart-rest-api';

const CoCart = new CoCartAPI({
  url: window.lcSubscribe?.cartUrl || 'https://learncare.test',
  version: 'cocart/v2'
});

export const coCartApiActions = (action, coupon, cb, errorHandler) => async () => {
  const cartKey = window.lcSubscribe?.key || '';

  const getCart = async () => {
    CoCart.version = 'cocart/v2';
    return CoCart.get(`cart/?cart_key=${cartKey}`);
  };

  const applyCoupon = async () => {
    CoCart.version = 'cocart/v1';
    return CoCart.post(`coupon/?cart_key=${cartKey}`, {
      coupon
    });
  };

  const removeCoupon = async () => {
    CoCart.version = 'cocart/v1';
    return CoCart.delete(`coupon/?cart_key=${cartKey}&coupon=${coupon}`, {
      coupon
    });
  };

  try {
    if (!action) {
      throw new Error('Ongeldige actie');
    }

    let response = '';

    if (action === 'get') {
      response = await getCart();
      console.log(response);
    }

    if (action === 'add') {
      response = await applyCoupon();
      if (response.data.message === 'Coupon already applied to cart.') {
        throw new Error('Waardebon is al toegepast');
      }
      response = await getCart();
    }

    if (action === 'remove') {
      response = await removeCoupon();
      console.log(response.data);
      if (response.data.message === 'Coupon already applied to cart.') {
        throw new Error('Waardebon is al toegepast');
      }
      response = await getCart();
    }

    cb({ ...response.data });
  } catch (e) {
    console.log(e);
    errorHandler(e);
  }
};
