import camelcaseKeys from 'camelcase-keys';
import { csrf } from '../utils/sanctum';
import axios from '../utils/axios';

import { contactActions } from './slices/contact';
import { companyActions } from './slices/company';
import { productActions } from './slices/product';
import { scheduleActions } from './slices/schedule';
import { orderActions } from './slices/order';
import { presentActions } from './slices/present';
import { settingsActions } from './slices/settings';
import { commentActions } from './slices/comments';
import { invoiceActions } from './slices/invoice';

const getActions = (type) => {
  let stateActions = false;
  if (type === 'contacts') {
    stateActions = contactActions;
  }

  if (type === 'invoices') {
    stateActions = invoiceActions;
  }

  if (type === 'invoice-payments') {
    stateActions = {};
  }

  if (type === 'companies') {
    stateActions = companyActions;
  }

  if (type === 'products') {
    stateActions = productActions;
  }

  if (type === 'schedules') {
    stateActions = scheduleActions;
  }

  if (type === 'present') {
    stateActions = presentActions;
  }

  if (type === 'orders') {
    stateActions = orderActions;
  }

  if (type === 'boards') {
    stateActions = settingsActions;
  }

  if (type === 'tasks') {
    stateActions = settingsActions;
  }

  if (type === 'comments') {
    stateActions = commentActions;
  }
  return stateActions;
};

export const getItems = (type, args) => async (dispatch) => {
  const stateActions = getActions(type);

  const fetchData = async () => {
    await csrf();
    let url = `/api/${type}`;

    // if (type === 'orders') {
    //   args.status = 'new';
    // }

    if (args) {
      Object.keys(args).forEach((key, i) => {
        url += `${i > 0 ? '&' : '?'}${key}=${args[key]}`;
      });
    }
    return axios.get(url);
  };

  try {
    if (!stateActions) {
      throw new Error('Error: ongeldige actie');
    }

    dispatch(stateActions.startLoading());
    const response = await fetchData();
    dispatch(stateActions.getAllSuccess(camelcaseKeys(response.data, { deep: true })));
  } catch (error) {
    dispatch(stateActions.hasError(error));
  }
};

export const getItem = (type, id, cb, args) => async () => {
  const stateActions = getActions(type);
  const fetchItem = async () => {
    let url = `/api/${type}/${id}`;

    if (args) {
      Object.keys(args).forEach((key, i) => {
        url += `${i > 0 ? '&' : '?'}${key}=${args[key]}`;
      });
    }
    return axios.get(url);
  };
  try {
    if (!stateActions) {
      throw new Error('Error: ongeldige actie');
    }

    const response = await fetchItem();
    const data = camelcaseKeys(response.data, { deep: true });
    cb(data);
    return data;
  } catch (e) {
    return false;
  }
};

export const saveItem = (type, data, cb, errorHandler) => async () => {
  const stateActions = getActions(type);
  const saveItemToApi = async () => {
    if (!data) {
      throw new Error('Error: ongeldige data');
    }

    if (data?.snelstart) {
      delete data.snelstart;
    }

    await csrf();
    if (data.id) {
      return axios.put(`/api/${type}/${data.id}`, { ...data });
    }

    return axios.post(`/api/${type}`, { ...data });
  };

  try {
    if (!stateActions) {
      throw new Error('Error: ongeldige actie');
    }
    const response = await saveItemToApi();
    const data = camelcaseKeys(response.data, { deep: true });
    cb(data);
    return data;
  } catch (e) {
    console.log(e);
    errorHandler(e);
    return e;
  }
};

export const deleteItem = (type, id, cb, errorHandler) => async () => {
  const stateActions = getActions(type);
  const deleteItemToApi = async () => {
    if (!id) {
      throw new Error('Error: ongeldige id');
    }

    await csrf();
    return axios.delete(`/api/${type}/${id}`);
  };

  try {
    if (!stateActions) {
      throw new Error('Error: ongeldige actie');
    }
    const response = await deleteItemToApi();
    const data = camelcaseKeys(response.data, { deep: true });
    cb(data);
    return data;
  } catch (e) {
    errorHandler(e);
    return e;
  }
};

export const changeOrderLineProduct = (id, productId, cb) => async () => {
  const moveToProduct = async () => axios.get(`/api/orderlines/${id}/move-to-product/${productId}`);
  try {
    await csrf();
    let response = false;
    response = await moveToProduct();
    const data = camelcaseKeys(response.data, { deep: true });
    cb({ error: false, data });
    return data;
  } catch (e) {
    cb({ error: true, message: e });
    return false;
  }
};

export const setOrderLineReminder = (id, reminder, cb) => async () => {
  const setReminder = async () => axios.post(`/api/orderlines/${id}/set-reminder`, { reminder });
  try {
    await csrf();
    let response = false;
    response = await setReminder();
    const data = camelcaseKeys(response.data, { deep: true });
    cb({ error: false, data });
    return data;
  } catch (e) {
    cb({ error: true, message: e });
    return false;
  }
};

export const quickAddContact = (data, cb) => async () => {
  const getContact = async () => axios.post(`/api/quick-add-contact`, { ...data });
  try {
    await csrf();
    let response = false;
    response = await getContact();
    const data = camelcaseKeys(response.data, { deep: true });
    cb({ error: false, data });
    return data;
  } catch (e) {
    cb({ error: true, message: e });
    return false;
  }
};

export const quickAddOrder = (data, cb) => async () => {
  const createOrder = async () => axios.post(`/api/orders/add-quick`, { ...data });
  try {
    await csrf();
    let response = false;
    response = await createOrder();
    console.log('quickAddOrder', response);
    const data = camelcaseKeys(response.data, { deep: true });
    cb({ error: false, warning: response.status === 201, data });
    return data;
  } catch (e) {
    cb({ error: true, message: e });
    return false;
  }
};

export const sendBulkMail = (data, cb) => async () => {
  const sendMail = async () => axios.post(`/api/products/create-bulk-mail`, { ...data });
  try {
    await csrf();
    let response = false;
    response = await sendMail();
    const data = camelcaseKeys(response.data, { deep: true });
    cb({ error: false, message: data.message });
    return data;
  } catch (e) {
    cb({ error: true, message: e });
    return false;
  }
};

export const migrateContact = (to, data, cb) => async () => {
  const migrate = async () => axios.post(`/api/migrate-contacts/${to}`, { ...data });
  try {
    await csrf();
    let response = false;
    response = await migrate();
    const data = camelcaseKeys(response.data, { deep: true });
    cb({ error: false, message: data.message });
    return data;
  } catch (e) {
    cb({ error: true, message: e });
    return false;
  }
};

export const productSaveBranches = (id, branches, cb) => async () => {
  const saveBranches = async () => axios.post(`/api/products/${id}/save-branches`, { branches });
  try {
    await csrf();
    let response = false;
    response = await saveBranches();
    const data = camelcaseKeys(response.data, { deep: true });
    cb({ error: false, message: data.message });
    return data;
  } catch (e) {
    cb({ error: true, message: e });
    return false;
  }
};

export const enableCertificateForOrderLine = (onderLines, cb) => async () => {
  const enableCertificateForOrderLineUrl = async () => axios.post(`/api/enable-certificates`, { onderLines });
  try {
    await csrf();
    let response = false;
    response = await enableCertificateForOrderLineUrl();
    const data = camelcaseKeys(response.data, { deep: true });
    cb({ error: false, data });
    return data;
  } catch (e) {
    cb({ error: true, data: e });
    return false;
  }
};

export const sendWelkomEmail = (orderLineId, isReminder, cb) => async () => {
  const sendWelkomEmailUrl = async () =>
    axios.get(`/api/orderlines/${orderLineId}/send-welkom${isReminder ? '?isReminder=true' : ''}`);
  try {
    await csrf();
    let response = false;
    response = await sendWelkomEmailUrl();
    const data = camelcaseKeys(response.data, { deep: true });
    cb({ error: false, data });
    return data;
  } catch (e) {
    cb({ error: true, data: e });
    return false;
  }
};

export const getLoginUrlElearning = (orderLineId, cb) => async () => {
  const getLearningUrl = async () => axios.get(`/api/orderlines/${orderLineId}/login-anewspring`);
  try {
    await csrf();
    let response = false;
    response = await getLearningUrl();
    const data = camelcaseKeys(response.data, { deep: true });
    cb({ error: false, data });
    return data;
  } catch (e) {
    cb({ error: true, message: e });
    return false;
  }
};

export const enableDisableElearning = (id, enable, force, cb) => async () => {
  const enableElearning = async () => axios.post(`/api/orderlines/${id}/enable-elearning?forced=${force}`);
  const disableElearning = async () => axios.post(`/api/orderlines/${id}/disable-elearning?forced=${force}`);
  try {
    await csrf();
    const response = enable ? await enableElearning() : await disableElearning();
    const { message } = response.data;
    cb({ error: false, message });
    return {
      message
    };
  } catch (e) {
    const message = e?.response?.data?.message || e.message;
    cb({ error: true, message });
    return false;
  }
};

export const enableDisableAccount = (id, enable, force, cb) => async () => {
  const enableAccount = async () => axios.get(`/api/contacts/${id}/enable-account`);
  const disableAccount = async () => axios.get(`/api/contacts/${id}/disable-account`);
  try {
    await csrf();
    let response = false;
    if (enable) {
      response = await enableAccount();
    } else {
      response = await disableAccount();
    }
    const data = camelcaseKeys(response.data, { deep: true });
    cb({ error: false, data });
    return data;
  } catch (e) {
    cb({ error: true, message: e });
    return false;
  }
};

export const createInvoiceFromOrder = (id, cb, errorHandler) => async () => {
  const createInvoice = async () => axios.post(`/api/orders/${id}/create-invoice?withInvoices=true&withTotals=true`);
  try {
    await csrf();
    const response = await createInvoice();
    const data = camelcaseKeys(response.data, { deep: true });
    cb({ error: false, data });
    return data;
  } catch (e) {
    errorHandler({ message: e.message });
    return false;
  }
};

export const viewInvoice = (id, cb, errorHandler) => async () => {
  const getInvoice = async () => axios.get(`/api/invoice/${id}`);
  try {
    await csrf();
    const response = await getInvoice();
    cb({ error: false, data: response });
    return response;
  } catch (e) {
    errorHandler({ message: e.message });
    return false;
  }
};

export const sendInvoiceToCustomer = (id, type, cb, errorHandler) => async () => {
  const send = async () => axios.post(`/api/invoices/${id}/send-invoice/${type}`);
  try {
    await csrf();
    const response = await send();
    const data = camelcaseKeys(response.data, { deep: true });
    cb({ error: false, data });
    return data;
  } catch (e) {
    errorHandler({ message: e.message });
    return false;
  }
};

export const printInvoice = (id, cb, errorHandler) => async () => {
  const send = async () => axios.post(`/api/invoices/${id}/print`);
  try {
    await csrf();
    const response = await send();
    const data = camelcaseKeys(response.data, { deep: true });
    cb({ error: false, data });
    return data;
  } catch (e) {
    errorHandler({ message: e.message });
    return false;
  }
};

export const createCreditForInvoice = (id, cb, errorHandler) => async () => {
  const send = async () => axios.post(`/api/invoices/${id}/create-credit?withInvoices=true&withTotals=true`);
  try {
    await csrf();
    const response = await send();
    const data = camelcaseKeys(response.data, { deep: true });
    cb({ error: false, data });
    return data;
  } catch (e) {
    errorHandler({ message: e.message });
    return false;
  }
};

export const verifyAccount = (id, hash, expires, signature, password, passwordRepeat, cb, errorHandler) => async () => {
  const send = async () =>
    axios.post(`/api/email/verify/${id}/${hash}?expires=${expires}&signature=${signature}`, {
      newPassword: password,
      newPasswordRepeat: passwordRepeat
    });
  try {
    await csrf();
    const response = await send();
    const data = camelcaseKeys(response.data, { deep: true });
    cb({ error: false, data });
    return data;
  } catch (e) {
    errorHandler({ message: e.message });
    return false;
  }
};

export const verifyIP = (userId, expires, signature, cb, errorHandler) => async () => {
  const send = async () => axios.get(`/wwr-auth/verify-ip/${userId}?expires=${expires}&signature=${signature}`, {});
  try {
    const response = await send();
    const data = camelcaseKeys(response.data, { deep: true });
    cb({ error: false, data });
    return data;
  } catch (e) {
    errorHandler({ message: e.message });
    return false;
  }
};

export const sendVerifyIpEmail = (cb, errorHandler) => async () => {
  const send = async () => axios.get(`/wwr-auth/resent-verify-ip`);
  try {
    await csrf();
    const response = await send();
    const data = camelcaseKeys(response.data, { deep: true });
    cb({ error: false, data });
    return data;
  } catch (e) {
    errorHandler({ message: e.message });
    return false;
  }
};

export const sendVerifyEmail = (id, cb, errorHandler) => async () => {
  const send = async () => axios.post(`/api/email/${id}/verification-notification`, {});
  try {
    await csrf();
    const response = await send();
    const data = camelcaseKeys(response.data, { deep: true });
    cb({ error: false, data });
    return data;
  } catch (e) {
    errorHandler({ message: e.message });
    return false;
  }
};

export const sendPasswordReset = (email, cb, errorHandler) => async () => {
  const send = async () => axios.post(`/api/email/forgot-password`, { email });
  try {
    await csrf();
    const response = await send();
    const data = camelcaseKeys(response.data, { deep: true });
    cb({ error: false, data });
    return data;
  } catch (e) {
    errorHandler({ message: e.message });
    return false;
  }
};

export const createNewPassword = (token, email, password, passwordRepeat, cb, errorHandler) => async () => {
  const send = async () =>
    axios.post(`/api/reset-password`, { token, email, password, password_confirmation: passwordRepeat });
  try {
    await csrf();
    const response = await send();
    const data = camelcaseKeys(response.data, { deep: true });
    cb({ error: false, data });
    return data;
  } catch (e) {
    errorHandler({ message: e.message });
    return false;
  }
};

export const validateTeacherDate = (data, cb, errorHandler) => async () => {
  const send = async () => axios.post(`/api/schedules/validate-teacher`, data);
  try {
    await csrf();
    const response = await send();
    const data = camelcaseKeys(response.data, { deep: true });
    cb({ error: false, data });
    return data;
  } catch (e) {
    errorHandler({ message: e.message });
    return false;
  }
};

const FileDownload = require('js-file-download');

export const getExporterData = (args, cb, error) => async () => {
  let url = `api/export`;

  if (args) {
    Object.keys(args).forEach((key, i) => {
      url += `${i > 0 ? '&' : '?'}${key}=${args[key]}`;
    });
  }

  let conf = {};
  if (args.excel && args.excel === 'yes') {
    conf = { responseType: 'blob' };
  }

  const getExportData = async () => axios.get(url, conf);
  try {
    await csrf();
    let response = false;
    response = await getExportData();
    console.log(response);
    if (args.excel && args.excel === 'yes') {
      FileDownload(response.data, `${args.type}.xlsx`);
      cb('download started');
      return 'downloaded';
    }

    const data = camelcaseKeys(response.data, { deep: true });
    cb(data);
    return data;
  } catch (e) {
    if (args.excel && args.excel === 'yes') {
      error({ error: true, message: 'Downloaded error' });
      return 'downloaded error';
    }
    error({ error: true, message: e });
    return false;
  }
};

export const connectSnelstart = (orderId, contactId, companyId, snelstartId, cb, error) => async () => {
  const connectOrderSnelstart = async () =>
    axios.post(`/api/connect-snelstart`, {
      orderId: typeof orderId !== 'undefined' && orderId ? orderId : null,
      contactId: typeof contactId !== 'undefined' && contactId ? contactId : null,
      companyId: typeof companyId !== 'undefined' && companyId ? companyId : null,
      snelstartId
    });
  try {
    await csrf();
    const response = await connectOrderSnelstart();
    console.log(response);
    const data = camelcaseKeys(response.data, { deep: true });
    cb(data);
    return data;
  } catch (e) {
    error({ error: true, message: e?.message ?? e });
    return false;
  }
};

export const toggleCompanyArchive = (companyId, cb) => async () => {
  const toggleArchive = async () => axios.put(`/api/companies/${companyId}/toggle-archive`);
  try {
    await csrf();
    const response = await toggleArchive();
    console.log(response);
    const data = camelcaseKeys(response.data, { deep: true });
    cb(data);
    return data;
  } catch (e) {
    cb({ error: true, message: e?.message ?? e });
    return false;
  }
};
