// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
// import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import { ProgressBarStyle } from './components/LoadingScreen';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import NotistackProvider from './components/NotistackProvider';

import SubscribeFormStepper from './components/_subscribe-form/SubscribeFormStepper';

// ----------------------------------------------------------------------

export default function SubscribeForm() {
  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <RtlLayout>
          <NotistackProvider>
            <GlobalStyles />
            <ProgressBarStyle />
            {/* <Settings /> */}
            <SubscribeFormStepper />
          </NotistackProvider>
        </RtlLayout>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
