import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import camelcaseKeys from 'camelcase-keys';
// utils
import { useNavigate } from 'react-router-dom';
import axiosLearncare from '../utils/axios';
import { csrf } from '../utils/sanctum';
import { PATH_AUTH } from '../routes/paths';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  need2FA: false,
  needIpCheck: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, need2FA, needIpCheck } = action.payload;
    return {
      ...state,
      isAuthenticated,
      need2FA,
      needIpCheck,
      isInitialized: true,
      user
    };
  },
  UPDATE_CONTACT_DETAILS: (state, action) => {
    const { contactDetails } = action.payload;
    const updatedUser = { ...state.user };
    updatedUser.contactDetails = { ...contactDetails };
    return {
      ...state,
      user: updatedUser
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'sanctum',
  login: () => Promise.resolve(),
  revalidate: () => Promise.resolve()
});

SanctumProvider.propTypes = {
  children: PropTypes.node
};

function SanctumProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  const checkIfEmailIsValid = async (email) => {
    try {
      const response = await axiosLearncare.get(`/api/email-valid/${email}`);
      return response.data;
    } catch (error) {
      return { error: true, message: error.message };
    }
  };

  const revalidate = async () => {
    try {
      const response = await axiosLearncare.get(`/api/me`, {
        maxRedirects: 0
      });
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: true,
          needIpCheck: false,
          user: camelcaseKeys(response.data, { deep: true })
        }
      });
      return true;
    } catch (error) {
      if (error?.errors?.one_time_password) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            need2FA: true,
            user: null
          }
        });
        return '2fa';
      }

      if (error.message === 'Your email address is not verified.') {
        navigate({ pathname: `${PATH_AUTH.verify}/current/email-not-verified` });
        return false;
      }

      let needIpCheck = false;

      if (error?.code === 'verified_ip' || error?.code === 'untrusted_ip') {
        needIpCheck = true;
      }
      if (error?.message === 'Your email address is not verified.') {
        needIpCheck = true;
      }
      if (error?.message === 'Your IP address is not verified yet, please verify') {
        needIpCheck = true;
      }
      if (error?.message === 'Your email address is not verified.') {
        needIpCheck = true;
      }

      if (error?.message === 'Unauthorized IP address, we send you a email') {
        needIpCheck = true;
      }

      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          needIpCheck,
          user: null
        }
      });

      if (needIpCheck) {
        return 'needIpCheck';
      }

      return false;
    }
  };

  const login = async (email, password) => {
    try {
      await csrf();
      return await axiosLearncare
        .post('/login', {
          email,
          password
        })
        .then(() => {
          revalidate(email);
        });
    } catch (e) {
      return e;
    }
  };

  const logout = async (toAccount) => {
    try {
      await csrf();
      return await axiosLearncare
        .get('/logout')
        .catch(() => {
          navigate({ pathname: `${PATH_AUTH.login}` });
        })
        .then(() => {
          dispatch({
            type: 'LOGOUT'
          });
          if (!toAccount) {
            navigate({ pathname: `${PATH_AUTH.login}` });
          } else {
            window.location.href = `${window.lcSubscribe.cartUrl}/inschrijven`;
          }
        });
    } catch (e) {
      if (!toAccount) {
        navigate({ pathname: `${PATH_AUTH.login}` });
      } else {
        window.location.href = `${window.lcSubscribe.cartUrl}/inschrijven`;
      }
      return e;
    }
  };

  const hasRoles = (roles) => {
    let hasOneOf = false;
    if (!state.user) {
      return hasOneOf;
    }
    roles.forEach((r) => {
      if (state.user.roles.includes(r)) {
        hasOneOf = true;
      }
    });

    return hasOneOf;
  };

  const updateUserContactDetails = (data) => {
    dispatch({
      type: 'UPDATE_CONTACT_DETAILS',
      payload: {
        contactDetails: camelcaseKeys(data, { deep: true })
      }
    });
  };

  useEffect(() => {
    if (!state.isInitialized) {
      const init = async () => {
        try {
          await revalidate();
        } catch (e) {
          console.log('init', e);
        }
      };
      init();
    }
  }, [state.isInitialized]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'sanctum',
        login,
        revalidate,
        logout,
        hasRoles,
        updateUserContactDetails,
        checkIfEmailIsValid
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, SanctumProvider };
