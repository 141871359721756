// material
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Container, Typography } from '@mui/material';

// components
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import useSettings from '../../hooks/useSettings';
import VerifyCodeForm from '../components/VerifyCode2faForm';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function VerifyCode2fa() {
  const settings = useSettings();

  return (
    <RootStyle title={`Login | ${settings.platform.name}`}>
      <MHidden width="mdDown">
        <SectionStyle>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h4" gutterBottom>
                Voer uw verificatiecode in.
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                Open de Google Authenticator-app op uw telefoon en voer de code in.
              </Typography>
            </Box>
          </Stack>
          <VerifyCodeForm />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
