import React from 'react';
import { Link, Typography } from '@mui/material';
import { isValid, format } from 'date-fns';
import { faUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import palette from '../theme/palette';

export const newSubscripeLineLabel = `Ik schrijf mij graag in voor de wekelijkse nieuwsbrief boordevol interessante informatie speciaal voor sociaal-financieel adviseurs`;

export const genderOptions = [
  { value: 'male', label: 'Man' },
  { value: 'female', label: 'Vrouw' },
  { value: '', label: 'Onbekend' }
];

export const typeOptions = [
  { label: 'Onbekend', value: '' },
  { label: 'Klant', value: 'customer' },
  { label: 'Docent', value: 'teacher' },
  { label: 'Admin', value: 'admin' }
];

export const genderToTitle = (gender) => {
  let title = '';
  if (gender && gender === 'male') {
    title = 'Dhr.';
  }
  if (gender && gender === 'female') {
    title = 'Mevr.';
  }
  return title;
};

export const wrapString = (string) => <Typography noWrap>{string}</Typography>;

export const getFullName = (contact) => `${genderToTitle(contact?.gender)} ${contact?.firstname} ${contact?.lastname}`;

export const getFullNameWithUserIndicator = (contact) => (
  <Typography component="span">
    <FontAwesomeIcon
      icon={faUser}
      color={contact?.user?.id ? palette.light.success.main : palette.light.warning.main}
      style={{ marginRight: '0.5rem', fontSize: '10px' }}
    />{' '}
    {getFullName(contact)}
  </Typography>
);

export const getJobTitleAtCompany = (contact) => {
  if (!contact.company?.name && !contact.job_title) {
    return '';
  }

  const jobTitle = contact.job_title ? `${contact.job_title} bij ` : 'Werkt bij ';
  if (!contact.company?.name) {
    return jobTitle;
  }

  return `${jobTitle} ${contact.company?.name}`;
};

export const getCityFromAddress = (item) => item?.address?.city || 'Onbekend';

export const getAddressString = (address) => {
  if (!address || !address.street || !address.postcode || !address.city) {
    return 'Adres niet geldig';
  }
  return `${address.street}\n${address.postcode} ${address.city}`;
};

export const getPhoneLink = (phone) => {
  if (!phone) {
    return 'Onbekend';
  }

  const cleanedPhone = phone.replace(/[^\d]/g, '');

  return (
    <Link href={`tel:${cleanedPhone}`} target="_blank" rel="noopener" underline="hover">
      {phone}
    </Link>
  );
};

export const getBirthday = (contact) => {
  const unknown = 'Onbekend';
  if (!contact || !contact.birthday) {
    return unknown;
  }

  const birthday = new Date(contact.birthday);
  if (!isValid(birthday)) {
    return unknown;
  }
  return format(birthday, 'dd-MM-yyyy');
};

export const getEmailLink = (email) => {
  if (!email) {
    return 'Onbekend';
  }

  return (
    <Link href={`mailto:${email}`} target="_blank" rel="noopener" underline="hover">
      {email}
    </Link>
  );
};

export const getNiceType = (contact) => {
  const unknown = 'Onbekend';
  if (!contact || !contact.type) {
    return unknown;
  }

  if (contact.type === 'customer') {
    return 'Klant';
  }

  if (contact.type === 'teacher') {
    return 'Leraar';
  }

  if (contact.type === 'admin') {
    return 'Admin';
  }
  return unknown;
};

export const getCustomerContactFromOrder = (order, displayInline) => {
  if (order?.contact?.lastname && order?.company?.name) {
    return (
      <Typography
        variant="caption"
        sx={{ color: 'text.secondary' }}
        whiteSpace="pre"
        overflow="hidden"
        maxWidth={!displayInline ? '165px' : '100%'}
      >{`${getFullName(order?.contact)}${!displayInline ? '\n' : ' - '}${order.company.name}`}</Typography>
    );
  }

  if (order?.contact?.lastname) {
    return (
      <Typography
        variant="caption"
        sx={{ color: 'text.secondary' }}
        whiteSpace="pre"
        overflow="hidden"
        maxWidth={!displayInline ? '165px' : '100%'}
      >
        {getFullName(order?.contact)}
      </Typography>
    );
  }

  if (order?.company?.name) {
    return (
      <Typography
        variant="caption"
        sx={{ color: 'text.secondary' }}
        whiteSpace="pre"
        overflow="hidden"
        maxWidth={!displayInline ? '165px' : '100%'}
      >
        {order.company.name}
      </Typography>
    );
  }

  return (
    <Typography
      variant="caption"
      sx={{ color: 'text.secondary' }}
      whiteSpace="pre"
      overflow="hidden"
      maxWidth={!displayInline ? '165px' : '100%'}
    >
      Onbekend
    </Typography>
  );
};
