import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { defaultInitialReducers, defaultInitialState } from '../defaults';

// ----------------------------------------------------------------------

const slice = createSlice({
  name: 'company',
  initialState: { ...defaultInitialState, orderBy: 'name', onlyActive: true },
  reducers: {
    ...defaultInitialReducers,
    setOnlyActive(state, action) {
      state.onlyActive = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const companyActions = slice.actions;

export const getCompanyById = (id, cb) => async () => {
  const getCompany = async () => axios.get(`/api/companies/${id}`);
  try {
    const response = await getCompany();
    cb({ error: false, data: response.data });
    return response.data;
  } catch (e) {
    cb({ error: true, message: e.message });
    return false;
  }
};
