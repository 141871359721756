import { createSlice } from '@reduxjs/toolkit';
// utils
import { defaultInitialReducers, defaultInitialState } from '../defaults';

// ----------------------------------------------------------------------

const slice = createSlice({
  name: 'present',
  initialState: { ...defaultInitialState, orderBy: 'schedule_id' },
  reducers: { ...defaultInitialReducers }
});

// Reducer
export default slice.reducer;

// Actions
export const presentActions = slice.actions;
