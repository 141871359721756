import { createSlice } from '@reduxjs/toolkit';
// utils
import { defaultInitialReducers, defaultInitialState } from '../defaults';

// ----------------------------------------------------------------------

const slice = createSlice({
  name: 'product',
  initialState: {
    ...defaultInitialState,
    orderBy: 'name',
    filterByTeachers: [],
    onlyActive: true,
    perPage: 250,
    filterByStatus: ''
  },
  reducers: {
    ...defaultInitialReducers,
    setFilterByTeacher(state, action) {
      console.log('setFilterByTeacher', action.payload);
      state.filterByTeachers = [...action.payload];
    },
    setOnlyActive(state, action) {
      console.log('setOnlyActive', action.payload);
      state.onlyActive = action.payload;
    },
    setFilterByStatus(state, action) {
      console.log('order slice', action.payload);
      state.filterByStatus = action.payload;
    },
    replaceItem(state, action) {
      const parentId = action.payload.parentId ?? action.payload.id;
      const parentIndex = state.items.findIndex((parent) => parent.id === parentId);
      if (parentIndex >= 0) {
        if (action.payload.parentId) {
          const childIndex = state.items[parentIndex].children.findIndex((child) => child.id === action.payload.id);
          if (childIndex >= 0) {
            state.items[parentIndex].children[childIndex] = { ...action.payload };
          }
        } else {
          state.items[parentIndex] = { ...action.payload };
        }
      }
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const productActions = slice.actions;
