// import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { csrf } from '../../utils/sanctum';
import { defaultInitialReducers, defaultInitialState } from '../defaults';

// ----------------------------------------------------------------------

const slice = createSlice({
  name: 'contact',
  initialState: { ...defaultInitialState, orderBy: 'lastname' },
  reducers: { ...defaultInitialReducers }
});

// Reducer
export default slice.reducer;

// Actions
export const contactActions = slice.actions;

export const getContactById = (id, cb) => async () => {
  const getContact = async () => axios.get(`/api/contacts/${id}`);
  try {
    const response = await getContact();
    cb(response.data);
    return response.data;
  } catch (e) {
    return false;
  }
};

export const addContact = (contact, cb) => async (dispatch) => {
  const saveData = async () => {
    await csrf();
    return axios.post(`/api/contacts`, { ...contact });
  };

  try {
    dispatch(slice.actions.startLoading());
    const response = await saveData();
    dispatch(slice.actions.saveContactSuccess(response.data));
    cb();
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    throw error;
  }
};
