import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faShoppingCart,
  faCalendarStar,
  faCalendarDay,
  faProjectDiagram,
  faUserFriends,
  faBuilding,
  faGraduationCap,
  faFileInvoice,
  faUser,
  faFileExport
} from '@fortawesome/pro-regular-svg-icons';

import { PATH_ACCOUNT, PATH_DASHBOARD } from '../../routes/paths';
import { platformSettings } from '../../config';
// ----------------------------------------------------------------------

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Main menu',
    items: [
      {
        title: 'Dashboard',
        roles: ['admin', 'teacher'],
        path: PATH_DASHBOARD.general.dashboard,
        icon: <FontAwesomeIcon icon={faCalendarDay} />
      }
      // { title: 'Taken', path: PATH_DASHBOARD.general.tasks, icon: <FontAwesomeIcon icon={faTasks} /> }
    ]
  },
  {
    subheader: 'Account',
    items: [
      {
        title: 'Bestellingen',
        roles: ['customer'],
        path: PATH_ACCOUNT.general.orders,
        icon: <FontAwesomeIcon icon={faShoppingCart} />
      },
      {
        title: 'Facturen',
        roles: ['customer'],
        path: PATH_ACCOUNT.general.invoices,
        icon: <FontAwesomeIcon icon={faFileInvoice} />
      },
      {
        title: 'Accountgegevens',
        roles: ['customer'],
        path: PATH_ACCOUNT.general.settings,
        icon: <FontAwesomeIcon icon={faUser} />
      }
      // { title: 'Taken', path: PATH_DASHBOARD.general.tasks, icon: <FontAwesomeIcon icon={faTasks} /> }
    ]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'Beheren',
    items: [
      {
        title: platformSettings.features.orders.title,
        roles: ['admin'],
        path: PATH_DASHBOARD.general.orders,
        icon: <FontAwesomeIcon icon={platformSettings.features.orders.icon} />
      },
      {
        title: platformSettings.features.products.title,
        path: PATH_DASHBOARD.general.products.root,
        roles: ['admin'],
        icon: <FontAwesomeIcon icon={platformSettings.features.products.icon} />,
        children: [
          { title: 'Overzicht', path: PATH_DASHBOARD.general.products.root },
          { title: 'Inhoud', path: PATH_DASHBOARD.general.products.descriptions },
          { title: 'connect', path: PATH_DASHBOARD.general.products.connect }
        ]
      },
      {
        title: 'Planning',
        roles: ['admin', 'teacher'],
        path: PATH_DASHBOARD.general.schedules,
        icon: <FontAwesomeIcon icon={faCalendarStar} />
      }
    ]
  },

  // RELATIONS
  // ----------------------------------------------------------------------
  {
    subheader: 'Relaties',
    items: [
      {
        title: 'Bedrijven',
        roles: ['admin'],
        path: PATH_DASHBOARD.general.companies,
        icon: <FontAwesomeIcon icon={faBuilding} />
      },
      {
        title: 'Contacten',
        roles: ['admin'],
        path: PATH_DASHBOARD.general.contacts,
        icon: <FontAwesomeIcon icon={faUserFriends} />
      }
    ]
  },
  // INVOICES
  // ----------------------------------------------------------------------
  {
    subheader: 'Facturatie',
    items: [
      {
        title: 'Facturen',
        path: PATH_DASHBOARD.general.invoices.root,
        roles: ['admin'],
        icon: <FontAwesomeIcon icon={faBuilding} />,
        children: [
          { title: 'Overzicht', path: PATH_DASHBOARD.general.invoices.root },
          { title: 'Verzamelfacturering', path: PATH_DASHBOARD.general.invoices.bulk_invoices }
        ]
      }
    ]
  },
  {
    subheader: 'Exporter',
    items: [
      {
        title: 'Export',
        roles: ['admin'],
        path: PATH_DASHBOARD.general.exporter,
        icon: <FontAwesomeIcon icon={faFileExport} />
      }
    ]
  }
];

export default sidebarConfig;
