import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  initialized: false,
  syncNeeded: false,
  notifications: null,
  tasks: []
};

const slice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    getTasksSuccess(state, action) {
      state.tasks = action.payload;
      state.initialized = true;
    },

    saveTasksSuccess(state, action) {
      state.tasks = action.payload;
      state.syncNeeded = false;
    },

    updateTaskValueByKey(state, action) {
      const i = state.tasks.findIndex((item) => action.payload.id === item.id);
      state.tasks[i][action.payload.key] = action.payload.value;
      state.syncNeeded = true;
      if (action.payload.cb) {
        action.payload.cb(state.tasks[i][action.payload.key]);
      }
    },

    addTimeLog(state, action) {
      const i = state.tasks.findIndex((item) => item.id === action.payload.id);
      const timeLog = {
        items: [...state.tasks[i].timeLog.items],
        totalPause: state.tasks[i].timeLog.totalPause + action.payload.timer.pause,
        total: state.tasks[i].timeLog.total + action.payload.timer.time
      };
      timeLog.items.push(action.payload.timer);
      state.tasks[i].timeLog = timeLog;
      state.syncNeeded = true;
      if (action.payload.cb) {
        action.payload.cb(state.tasks[i][action.payload.key]);
      }
    },

    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET NOTIFICATIONS
    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteUser, updateTaskValueByKey, addTimeLog } = slice.actions;
