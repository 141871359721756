import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import AddressFields from '../_dashboard/Address/AddressFields';

const invoiceDetailsSchema = {
  invoiceType: Yup.string().required('Factuur type is verplicht'),
  name: Yup.string().when('invoiceType', {
    is: 'business',
    then: Yup.string().required('Naam is verplicht')
  }),
  invoiceTitle: Yup.string().when('invoiceType', {
    is: 'business',
    then: Yup.string().required('Contactpersoon / afdeling is verplicht')
  }),
  invoiceTitleTav: Yup.string().when('invoiceType', {
    is: 'business',
    then: Yup.string().required('T.a.v. is verplicht')
  }),
  invoiceFeature: Yup.string(),
  invoiceEmail: Yup.string().when('invoiceType', {
    is: 'business',
    then: Yup.string().required('Email is verplicht')
  }),
  invoicePhone: Yup.string().when('invoiceType', {
    is: 'business',
    then: Yup.string().required('Telefoonnr. is verplicht')
  }),
  invoiceAddress: Yup.object({
    invoiceType: Yup.string(),
    street: Yup.string().when('invoiceType', {
      is: 'business',
      then: Yup.string().required('Straat is verplicht')
    }),
    // number: Yup.string().when('invoiceType', {
    //   is: 'business',
    //   then: Yup.string().required('Huisnr. is verplicht')
    // }),
    // numberAddition: Yup.string(),
    postcode: Yup.string().when('invoiceType', {
      is: 'business',
      then: Yup.string().required('Postcode is verplicht')
    }),
    city: Yup.string().when('invoiceType', {
      is: 'business',
      then: Yup.string().required('Plaats is verplicht')
    })
  })
};

const InvoiceDetailsForm = ({ cart, invoiceDetails, handleBack, onNextHandler }) => {
  const NewCompanySchema = Yup.object().shape(invoiceDetailsSchema);

  const getGenderTitle = (gender) => {
    if (gender === 'male') {
      return 'Dhr. ';
    }

    if (gender === 'female') {
      return 'Mevr. ';
    }

    if (gender === 'different') {
      return 'Beste ';
    }

    return '';
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      invoiceType: invoiceDetails?.invoiceType || 'business',
      name: invoiceDetails?.name || '',
      invoiceFeature: invoiceDetails?.invoiceFeature || '',
      invoiceTitleTav: invoiceDetails?.invoiceTitlePrefix || '',
      invoiceTitle: invoiceDetails?.invoiceTitle || '',
      invoiceEmail: invoiceDetails?.invoiceEmail || '',
      invoicePhone: invoiceDetails?.invoicePhone || '',
      invoiceAddress: {
        id: invoiceDetails?.id || null,
        invoiceType: invoiceDetails?.invoiceType || 'business',
        street: invoiceDetails?.invoiceAddress?.street || '',
        // number: invoiceDetails?.invoiceAddress?.number || '',
        // numberAddition: invoiceDetails?.invoiceAddress?.numberAddition || '',
        postcode: invoiceDetails?.invoiceAddress?.postcode || '',
        city: invoiceDetails?.invoiceAddress?.city || '',
        country: 'nl'
      }
    },
    validationSchema: NewCompanySchema,
    onSubmit: async (values) => {
      if (values.invoiceType === 'business') {
        cart.customer.billing_address.billing_type = 'business';
        cart.customer.billing_address.billing_invoice_feature = values.invoiceFeature;
        cart.customer.billing_address.billing_company = values.name;
        cart.customer.billing_address.billing_contact_prefix = values.invoiceTitleTav;
        cart.customer.billing_address.billing_contact = values.invoiceTitle;
        cart.customer.billing_address.billing_phone = values.invoicePhone;
        cart.customer.billing_address.billing_email = values.invoiceEmail;

        cart.customer.billing_address.billing_address_1 = `${values.invoiceAddress.street}`;
        cart.customer.billing_address.billing_address_street = values.invoiceAddress.street;
        // cart.customer.billing_address.billing_address_number = values.invoiceAddress.number;
        // cart.customer.billing_address.billing_address_number_addition = values.invoiceAddress.numberAddition;
        cart.customer.billing_address.billing_city = values.invoiceAddress.city;
        cart.customer.billing_address.billing_postcode = values.invoiceAddress.postcode;
      } else {
        cart.customer.billing_address.billing_type = 'private';
        cart.customer.billing_address.billing_invoice_feature = '';
        cart.customer.billing_address.billing_company = '';
        cart.customer.billing_address.billing_contact_prefix = '';
        cart.customer.billing_address.billing_contact = `${getGenderTitle(
          cart.customer.shipping_address.shipping_gender
        )}${cart.customer.shipping_address.shipping_first_name} ${cart.customer.shipping_address.shipping_last_name}`;
        cart.customer.billing_address.billing_phone = values.phone;
        cart.customer.billing_address.billing_email = values.email;

        cart.customer.billing_address.billing_address_1 = cart.customer.shipping_address.shipping_address_1;
        cart.customer.billing_address.billing_address_street = cart.customer.shipping_address.shipping_address_street;
        // cart.customer.billing_address.billing_address_number = cart.customer.shipping_address.shipping_address_number;
        // cart.customer.billing_address.billing_address_number_addition =
        //   cart.customer.shipping_address.shipping_address_number_addition;
        cart.customer.billing_address.billing_city = cart.customer.shipping_address.shipping_city;
        cart.customer.billing_address.billing_postcode = cart.customer.shipping_address.shipping_postcode;
      }
      onNextHandler(values, cart);
    }
  });

  const { values, setFieldValue, errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik} fullWidth>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Card sx={{ p: 3 }}>
              <Typography textAlign="center" variant="h5" sx={{ mb: 2 }}>
                Factuurgegevens
              </Typography>
              <Stack spacing={3}>
                <Stack>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Type</FormLabel>
                    <RadioGroup
                      row
                      aria-label="approved"
                      defaultValue={values.invoiceType}
                      name="approved"
                      onChange={(e) => {
                        setFieldValue('invoiceType', e.target.value);
                        setFieldValue('invoiceAddress.invoiceType', e.target.value);
                      }}
                    >
                      <FormControlLabel value="private" control={<Radio />} label="Particulier" />
                      <FormControlLabel value="business" control={<Radio />} label="Zakelijk" />
                    </RadioGroup>
                  </FormControl>
                </Stack>
                {values.invoiceType === 'private' && (
                  <>
                    <Typography>De factuur wordt verzonden naar de deelnemer</Typography>
                  </>
                )}
                {values.invoiceType === 'business' && (
                  <>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <TextField
                        fullWidth
                        label="Bedrijfsnaam"
                        {...getFieldProps('name')}
                        error={!!touched?.name && !!errors?.name}
                        helperText={!!touched?.name && errors?.name}
                      />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <TextField
                        fullWidth
                        label="Kenmerknummer / ordernummer"
                        {...getFieldProps('invoiceFeature')}
                        error={!!touched?.invoiceFeature && !!errors?.invoiceFeature}
                        helperText={!!touched?.invoiceFeature && errors?.invoiceFeature}
                      />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <FormControl fullWidth>
                        <InputLabel id="invoiceTitle-tav-label">T.a.v</InputLabel>
                        <Select
                          labelId="invoiceTitle-tav-label"
                          id="invoiceTitle-tav"
                          label="T.a.v"
                          {...getFieldProps('invoiceTitleTav')}
                          error={touched.invoiceTitleTav && errors.invoiceTitleTav}
                        >
                          <MenuItem value="Dhr.">Dhr.</MenuItem>
                          <MenuItem value="Mevr.">Mevr.</MenuItem>
                          <MenuItem value="Afdeling">Afdeling</MenuItem>
                        </Select>
                      </FormControl>
                      <TextField
                        fullWidth
                        label="Contactpersoon / afdeling"
                        {...getFieldProps('invoiceTitle')}
                        error={!!touched?.invoiceTitle && !!errors?.invoiceTitle}
                        helperText={!!touched?.invoiceTitle && errors?.invoiceTitle}
                      />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <TextField
                        fullWidth
                        label="Telefoonnummer"
                        {...getFieldProps('invoicePhone')}
                        error={touched.invoicePhone && errors.invoicePhone}
                        helperText={touched.invoicePhone && errors.invoicePhone}
                      />
                      <TextField
                        fullWidth
                        label="E-mailadres"
                        {...getFieldProps('invoiceEmail')}
                        error={touched.invoiceEmail && errors.invoiceEmail}
                        helperText={touched.invoiceEmail && errors.invoiceEmail}
                      />
                    </Stack>
                    <Typography variant="subtitle2">Factuuradres:</Typography>
                    <AddressFields cammelCase formik={formik} objectName="invoiceAddress" />
                  </>
                )}
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 3, sm: 2 }}
                  justifyContent="space-between"
                  sx={{ my: 2 }}
                >
                  <Button variant="text" color="inherit" onClick={handleBack} style={{ marginRight: '1rem' }}>
                    Terug
                  </Button>
                  <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                    Verder
                  </LoadingButton>
                </Stack>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
};

export default InvoiceDetailsForm;

InvoiceDetailsForm.propTypes = {
  cart: PropTypes.object,
  invoiceDetails: PropTypes.object,
  handleBack: PropTypes.func,
  onNextHandler: PropTypes.func
};
