import { lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        // { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'password-reset/:token/:email', element: <CreateNewPassword /> },
        {
          path: 'verify/:id/:hash/:expires/:signature',
          element: <VerifyCode />
        },
        {
          path: 'verify/:id/:hash',
          element: <VerifyCode />
        },
        {
          path: 'verify-ip/:expires/:signature',
          element: (
            <GuestGuard>
              <VerifyIP />
            </GuestGuard>
          )
        },
        {
          path: 'verify-ip',
          element: (
            <GuestGuard>
              <VerifyIP />
            </GuestGuard>
          )
        },
        {
          path: 'verify-2fa',
          element: (
            <GuestGuard>
              <Verify2FA />
            </GuestGuard>
          )
        }
      ]
    },
    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <RoleBasedGuard accessibleRoles={['admin', 'teacher']}>
            <DashboardLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/overzicht" replace /> },
        { path: 'overzicht', element: <PageDashboard /> },
        {
          path: 'instellingen',
          element: <PageSettings />
        },
        { path: 'instellingen/2fa', element: <Page2faConfigure /> },
        { path: 'exporteren', element: <PageExporter /> },
        { path: 'taken', element: <PageTasks /> },
        { path: 'orders', element: <PageOrders /> },
        {
          path: 'order',
          children: [
            { element: <Navigate to="/dashboard/orders" replace /> },
            { path: 'details/:id', element: <PageOrder /> },
            { path: 'nieuw', element: <PageOrderEAD /> },
            { path: 'bewerken/:id', element: <PageOrderEAD /> }
          ]
        },
        {
          path: 'producten',
          children: [
            { element: <PageProducts /> },
            { path: '', element: <PageProducts /> },
            { path: 'inhoud', element: <PageProductsDescriptions /> },
            { path: 'connect', element: <PageProductsConnect /> },
            { path: 'connect/:id', element: <PageProductsConnect /> }
          ]
        },
        {
          path: 'product',
          children: [
            { element: <Navigate to="/dashboard/producten" replace /> },
            { path: 'details/:id/:scheduleId', element: <PageProduct /> },
            { path: 'details/:id', element: <PageProduct /> },
            { path: 'nieuw', element: <PageProductCreate /> }
          ]
        },
        { path: 'planning', element: <PageSchedules /> },
        {
          path: 'bedrijven',
          element: <PageCompanies />
        },
        {
          path: 'bedrijf',
          children: [
            { element: <Navigate to="/dashboard/bedrijven" replace /> },
            { path: 'details/:id', element: <PageCompany /> },
            { path: 'nieuw', element: <PageCompanyCreate /> }
          ]
        },
        { path: 'contacten', element: <PageContacts /> },
        {
          path: 'contact',
          children: [
            { element: <Navigate to="/dashboard/contacts" replace /> },
            { path: 'details/:id', element: <PageContact /> },
            { path: 'nieuw', element: <PageContactCreate /> }
          ]
        },
        {
          path: 'facturen',
          children: [
            { path: '', element: <PageInvoices /> },
            { path: 'verzamelfacturering', element: <PageBulkInvoices /> }
          ]
        }
      ]
    },

    {
      path: 'account',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/account/bestellingen" replace /> },
        { path: 'facturen', element: <PageAccountInvoices /> },
        { path: 'bestellingen', element: <PageAccountOrders /> },
        { path: 'gegevens', element: <PageAccountSettings /> }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <Navigate to="/dashboard/overzicht" replace />,
      children: [{ element: <LandingPage /> }]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
const CreateNewPassword = Loadable(lazy(() => import('../pages/authentication/CreateNewPassword')));
const Verify2FA = Loadable(lazy(() => import('../2fa/pages/VerifyCode2fa')));
const VerifyIP = Loadable(lazy(() => import('../pages/authentication/VerifyIP')));
const Page2faConfigure = Loadable(lazy(() => import('../2fa/pages/ConfigureTwoFactor')));

// Dashboard
const PageDashboard = Loadable(lazy(() => import('../pages/dashboard/Dashboard')));
const PageSettings = Loadable(lazy(() => import('../pages/dashboard/settings/Settings')));
const PageExporter = Loadable(lazy(() => import('../pages/dashboard/Exporter')));
const PageTasks = Loadable(lazy(() => import('../pages/dashboard/Tasks')));
// Management
const PageOrders = Loadable(lazy(() => import('../pages/dashboard/order/Orders')));
const PageOrder = Loadable(lazy(() => import('../pages/dashboard/order/Order')));
const PageOrderEAD = Loadable(lazy(() => import('../pages/dashboard/order/OrderEAD')));
const PageProducts = Loadable(lazy(() => import('../pages/dashboard/product/Products')));
const PageProductsConnect = Loadable(lazy(() => import('../pages/dashboard/product/ProductsConnect')));
const PageProductsDescriptions = Loadable(lazy(() => import('../pages/dashboard/product/ProductsDescriptions')));
const PageProduct = Loadable(lazy(() => import('../pages/dashboard/product/Product')));
const PageProductCreate = Loadable(lazy(() => import('../pages/dashboard/product/ProductNew')));
const PageSchedules = Loadable(lazy(() => import('../pages/dashboard/schedule/Schedules')));
// Relations -- COMPANIES
const PageCompanies = Loadable(lazy(() => import('../pages/dashboard/companies/Companies')));
const PageCompany = Loadable(lazy(() => import('../pages/dashboard/companies/Company')));
const PageCompanyCreate = Loadable(lazy(() => import('../pages/dashboard/companies/CompanyCreate')));
// Relations -- CONTACTS
const PageContacts = Loadable(lazy(() => import('../pages/dashboard/contact/Contacts')));
const PageContact = Loadable(lazy(() => import('../pages/dashboard/contact/Contact')));
const PageContactCreate = Loadable(lazy(() => import('../pages/dashboard/contact/ContactCreate')));
// Relations -- CONTACTS
const PageInvoices = Loadable(lazy(() => import('../pages/dashboard/invoices/Invoices')));
const PageBulkInvoices = Loadable(lazy(() => import('../pages/dashboard/invoices/BulkInvoices')));
// ACCOUNT -- CUSTOMER
const PageAccountSettings = Loadable(lazy(() => import('../pages/account/Settings')));
const PageAccountInvoices = Loadable(lazy(() => import('../pages/account/Invoices')));
const PageAccountOrders = Loadable(lazy(() => import('../pages/account/Orders')));

const NotFound = Loadable(lazy(() => import('../pages/Page404')));
// Main
const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
