import camelcaseKeys from 'camelcase-keys';
import axios from '../../utils/axios';
import { csrf } from '../../utils/sanctum';

export const getSetup2Fa = (cb, errorHandler) => async () => {
  const getSetup = async () => axios.get(`/wwr-2fa/setup`);
  try {
    const response = await getSetup();
    const data = camelcaseKeys(response.data, { deep: true });
    cb({ error: false, data });
    return data;
  } catch (e) {
    errorHandler({ message: e.message });
    return false;
  }
};

export const setup2Fa = (data, cb, errorHandler) => async () => {
  const send = async () => axios.post(`wwr-2fa/setup`, data);
  try {
    await csrf();
    const response = await send();
    const data = camelcaseKeys(response.data, { deep: true });
    cb({ error: false, data });
    return data;
  } catch (e) {
    errorHandler({ message: e.message });
    return false;
  }
};

export const verify2Fa = (data, cb, errorHandler) => async () => {
  const send = async () => axios.post(`wwr-2fa/verify`, data);
  try {
    await csrf();
    const response = await send();
    const data = camelcaseKeys(response.data, { deep: true });
    cb({ error: false, data });
    return data;
  } catch (e) {
    errorHandler({ message: e.message });
    return false;
  }
};
