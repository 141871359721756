import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import nl from 'date-fns/locale/nl';
import { isValid } from 'date-fns';

import {
  Card,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Box,
  FormControlLabel,
  FormGroup,
  Switch,
  Checkbox
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import DatePicker from '@mui/lab/DatePicker';
import { PATH_DASHBOARD } from '../../../routes/paths';

import CompanySelect from '../company/CompanySelect';
import AddressFields from '../Address/AddressFields';
import { saveItem } from '../../../redux/api-actions';
import { useDispatch } from '../../../redux/store';

import { getDate } from '../../../utils/date-helpers';
import { newSubscripeLineLabel } from '../../../utils/contact';

export const CONTACTSCHEMA = {
  gender: Yup.string().required('Geslacht is verplicht'),
  firstname: Yup.string().required('Voornaam is verplicht'),
  lastname: Yup.string().required('Achternaam is verplicht'),
  birthday: Yup.string(),
  address: Yup.object({
    street: Yup.string(),
    // number: Yup.string(),
    // houseNumberAddition: Yup.string(),
    postcode: Yup.string(),
    city: Yup.string()
  }),
  email: Yup.string().email().required('Email is verplicht'),
  phone: Yup.string(),
  relation_number: Yup.string(),
  type: Yup.string().required('Type contact is verplicht'),
  job_title: Yup.string(),
  company_id: Yup.number(),
  is_company_manager: Yup.boolean(),
  external_id: Yup.number()
};

const ContactForm = (props) => {
  const { currentContact, isEdit, onCancelClick, onSaved } = props;
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { hasError } = useSelector((state) => state.contacts);

  const NewContactSchema = Yup.object().shape(CONTACTSCHEMA);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      gender: currentContact?.gender || '',
      firstname: currentContact?.firstname || '',
      lastname: currentContact?.lastname || '',
      address: {
        id: currentContact?.address?.id || null,
        street: currentContact?.address?.street || '',
        // number: currentContact?.address?.number || '',
        // number_addition: currentContact?.address?.numberAddition || '',
        postcode: currentContact?.address?.postcode || '',
        city: currentContact?.address?.city || '',
        country: 'nl'
      },
      birthday: currentContact?.birthday || '',
      email: currentContact?.email || '',
      phone: currentContact?.phone || '',
      relation_number: currentContact?.relationNumber || '',
      type: currentContact?.type || 'customer',
      job_title: currentContact?.jobTitle || '',
      company: currentContact?.company || null,
      company_id: currentContact?.company?.id || -1,
      is_company_manager: currentContact?.isCompanyManager || false,
      external_id: currentContact?.externalId || ''
    },
    validationSchema: NewContactSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      const contact = {
        ...values,
        id: currentContact?.id || null,
        company_id: values.company_id === -1 ? null : values.company_id
      };

      delete contact.company;
      dispatch(
        saveItem(
          'contacts',
          contact,
          (data) => {
            setSubmitting(false);
            enqueueSnackbar(!isEdit ? 'Contact aangemaakt' : 'Gegevens opgeslagen', { variant: 'success' });
            if (contact.id) {
              onSaved(data);
            } else {
              navigate({ pathname: `${PATH_DASHBOARD.general.contact.details}/${data.id}` });
            }
          },
          (error) => {
            enqueueSnackbar(error.message, { variant: 'error' });
            setSubmitting(false);
            setErrors(error.errors);
          }
        )
      );
    }
  });

  const { errors, values, touched, setTouched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;
  const birthDateField = getFieldProps('birthday');

  console.log('errors', errors);

  return (
    <FormikProvider value={formik} fullWidth>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <FormControl fullWidth>
                    <InputLabel id="type-label">Type</InputLabel>
                    <Select
                      labelId="type-label"
                      id="type"
                      label="Type"
                      {...getFieldProps('type')}
                      error={touched.type && errors.type}
                    >
                      <MenuItem value="customer">Klant</MenuItem>
                      <MenuItem value="teacher">Leraar</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    fullWidth
                    label="Extern ID"
                    type="number"
                    {...getFieldProps('external_id')}
                    error={touched.external_id && errors.external_id?.length > 0}
                    helperText={touched.external_id && errors.external_id}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <FormControl fullWidth>
                    <InputLabel id="gender-label">Geslacht</InputLabel>
                    <Select
                      labelId="gender-label"
                      id="gender"
                      label="Geslacht"
                      {...getFieldProps('gender')}
                      error={touched.gender && errors.gender}
                    >
                      <MenuItem value="male">Man</MenuItem>
                      <MenuItem value="female">Vrouw</MenuItem>
                      <MenuItem value="different">Anders</MenuItem>
                      <MenuItem value="unknown">Onbekend</MenuItem>
                    </Select>
                  </FormControl>
                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={nl}>
                    <DatePicker
                      label="Geboortedatum"
                      mask="__-__-____"
                      value={birthDateField.value}
                      onChange={(newValue) => {
                        if (isValid(newValue)) {
                          setFieldValue('birthday', getDate(newValue));
                        }
                      }}
                      onBlur={() => {
                        setTouched('birthday', true);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={touched.birthday && errors.birthday?.length > 0}
                          helperText={touched.birthday && errors.birthday}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <TextField
                    fullWidth
                    label="Voornaam"
                    {...getFieldProps('firstname')}
                    error={touched.firstname && errors.firstname}
                    helperText={touched.firstname && errors.firstname}
                  />
                  <TextField
                    fullWidth
                    label="Achternaam"
                    {...getFieldProps('lastname')}
                    error={touched.lastname && errors.lastname}
                    helperText={touched.lastname && errors.lastname}
                  />
                </Stack>
                <Typography variant="subtitle2">Communicatie:</Typography>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <TextField
                    fullWidth
                    label="Telefoonnummer"
                    {...getFieldProps('phone')}
                    error={touched.phone && errors.phone}
                    helperText={touched.phone && errors.phone}
                  />
                  <TextField
                    fullWidth
                    label="E-mailadres"
                    {...getFieldProps('email')}
                    error={touched.email && errors.email}
                    helperText={touched.email && errors.email}
                  />
                </Stack>
                <Typography variant="subtitle2">Adres:</Typography>
                <AddressFields formik={formik} objectName="address" />
                <Typography variant="subtitle2">Bedrijf:</Typography>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <CompanySelect
                    withPrivateOption
                    current={values.company || null}
                    onChange={(value) => {
                      setFieldValue('company', value);
                      setFieldValue('company_id', value?.id);
                    }}
                    onBlur={() => {
                      setTouched('company_id', true);
                    }}
                  />
                  <FormGroup>
                    <FormControlLabel
                      disabled={!values.company_id}
                      control={
                        <Switch
                          checked={!!values.is_company_manager}
                          onChange={() => {
                            setFieldValue('is_company_manager', !values.is_company_manager);
                          }}
                        />
                      }
                      label="Manager"
                    />
                  </FormGroup>
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <TextField
                    fullWidth
                    label="Functie"
                    {...getFieldProps('job_title')}
                    error={touched.job_title && errors.job_title}
                    helperText={touched.job_title && errors.job_title}
                  />
                </Stack>
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                  {isEdit && (
                    <Button variant="text" onClick={onCancelClick} style={{ marginRight: '1rem' }}>
                      Annuleren
                    </Button>
                  )}
                  <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                    {!isEdit ? 'Contact toevoegen' : 'Opslaan'}
                  </LoadingButton>
                </Box>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
};

export default ContactForm;

ContactForm.propTypes = {
  isEdit: PropTypes.bool,
  currentContact: PropTypes.object,
  onCancelClick: PropTypes.func,
  onSaved: PropTypes.func
};
