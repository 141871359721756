export const defaultInitialState = {
  isLoading: false,
  error: false,
  initialized: false,
  notifications: null,
  items: [],
  item: {},
  search: '',
  searchChanged: true,
  order: 'asc',
  orderBy: 'id',
  firstPageUrl: null,
  lastPageUrl: null,
  currentPage: 1,
  links: [],
  nextPageUrl: null,
  prevPageUrl: null,
  total: 0,
  perPage: 10
};

export const defaultInitialReducers = {
  getAllSuccess(state, action) {
    state.items = [...action.payload.data];
    state.firstPageUrl = action.payload.meta.firstPageUrl;
    state.lastPageUrl = action.payload.meta.lastPageUrl;
    state.currentPage = action.payload.meta.currentPage;
    state.links = action.payload.meta.links;
    state.nextPageUrl = action.payload.meta.nextPageUrl;
    state.prevPageUrl = action.payload.meta.prevPageUrl;
    state.total = action.payload.meta.totalItem;
    state.perPage = action.payload.meta.perPage;
    state.isLoading = false;
    state.searchChanged = false;
  },

  changeOrder(state) {
    state.order = state.order === 'asc' ? 'desc' : 'asc';
  },

  changeOrderBy(state, action) {
    state.orderBy = action.payload;
  },

  changePerPage(state, action) {
    state.perPage = action.payload;
  },

  changePage(state, action) {
    state.currentPage = action.payload;
  },

  changeSearch(state, action) {
    console.log('searche', action.payload);
    state.search = action.payload;
    state.currentPage = 1;
    state.searchChanged = true;
  },

  saveItemSuccess(state) {
    state.isLoading = false;
  },

  // START LOADING
  startLoading(state) {
    state.isLoading = true;
  },

  // HAS ERROR
  hasError(state, action) {
    console.log(action.payload);
    state.isLoading = false;
    state.error = action.payload;
  },

  // GET NOTIFICATIONS
  getNotificationsSuccess(state, action) {
    state.isLoading = false;
    state.notifications = action.payload;
  }
};

export const createSliceWithDefaults = (name, stateArgs, stateReducers) => {
  const initialState = { ...defaultInitialState, ...stateArgs };
  const reducers = { ...defaultInitialReducers, ...stateReducers };
  return {
    name,
    initialState,
    reducers
  };
};
