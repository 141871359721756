import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: false,
  initialized: false,
  syncNeeded: false,
  notifications: [],
  boards: [],
  selectedBoard: null,
  tasks: [],
  selectedTask: null
};

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    getAllSuccess(state, action) {
      state.boards = action.payload.boards;
      state.tasks = action.payload.globalTasks;
      state.initialized = true;
    },

    saveItemSuccess(state) {
      state.isLoading = false;
    },

    afterBoardSaved(state, action) {
      const boardIndex = state.boards.findIndex((board) => board.id === action.payload.id);
      if (boardIndex >= 0) {
        const boards = [...state.boards];
        boards[boardIndex] = action.payload;
        state.boards = boards;
        state.selectedBoard = null;
      } else {
        state.boards.push(action.payload);
        state.selectedBoard = null;
      }
    },

    boardNameChanged(state, action) {
      state.selectedBoard.name = action.payload.name;
    },

    selectBoard(state, action) {
      state.selectedBoard = action.payload;
    },

    addTaskToBoard(state, action) {
      const boardIndex = state.boards.findIndex((board) => board.id === action.payload.board.id);
      if (boardIndex >= 0) {
        const boards = [...state.boards];
        boards[boardIndex].tasks.push(action.payload.task);
        state.boards = boards;
        state.selectedBoard = boards[boardIndex];
      } else {
        state.selectedBoard.tasks.push(action.payload.task);
      }
    },

    deleteTaskFromBoard(state, action) {
      const boardIndex = state.boards.findIndex((board) => board.id === action.payload.board.id);
      if (boardIndex >= 0) {
        const boards = [...state.boards];
        const taskIndex = boards[boardIndex].tasks.findIndex((t) => t.id === action.payload.task.id);
        if (taskIndex >= 0) {
          boards[boardIndex].tasks.splice(taskIndex, 1);
        }
        state.boards = boards;
        state.selectedBoard = boards[boardIndex];
      } else {
        const taskIndex = state.selectedBoard.tasks.findIndex((t) => t.id === action.payload.task.id);
        state.selectedBoard.tasks.splice(taskIndex, 1);
      }
    },

    afterTaskSave(state, action) {
      const taskIndex = state.tasks.findIndex((task) => task.id === action.payload.id);
      if (taskIndex >= 0) {
        state.tasks[taskIndex] = action.payload;
        state.selectedTask = null;
      } else {
        state.tasks.push(action.payload);
        state.selectedTask = null;
      }
    },

    selectTask(state, action) {
      state.selectedTask = action.payload;
    },

    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET NOTIFICATIONS
    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const settingsActions = slice.actions;
