import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import settingsReducer from './slices/settings';
import companyReducer from './slices/company';
import contactReducer from './slices/contact';
import productsReducer from './slices/product';
import scheduleReducer from './slices/schedule';
import tasksReducer from './slices/task';
import orderReducer from './slices/order';
import invoiceReducer from './slices/invoice';
import calendarReducer from './slices/calendar';
import commentReducer from './slices/comments';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const rootReducer = combineReducers({
  settings: settingsReducer,
  companies: companyReducer,
  contacts: contactReducer,
  schedules: scheduleReducer,
  products: productsReducer,
  tasks: tasksReducer,
  orders: orderReducer,
  invoice: invoiceReducer,
  calendar: calendarReducer,
  comments: commentReducer,
  invoices: invoiceReducer
});

export { rootPersistConfig, rootReducer };
