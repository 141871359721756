import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import shieldFill from '@iconify/icons-eva/shield-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import { alpha } from '@mui/material/styles';
import { Button, Box, Divider, MenuItem, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-regular-svg-icons';
// components
import { MIconButton } from '../../components/@material-extend';
import MenuPopover from '../../components/MenuPopover';
import useAuth from '../../hooks/useAuth';

import { PATH_DASHBOARD } from '../../routes/paths';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  // { label: 'Home', icon: homeFill, linkTo: '/' },
  // { label: 'Profile', icon: personFill, linkTo: '#' },
  { label: 'Settings', icon: settings2Fill, linkTo: PATH_DASHBOARD.general.settings },
  { label: 'Beveiliging', icon: shieldFill, linkTo: PATH_DASHBOARD.general.twoFactorConfig }
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const auth = useAuth();
  const anchorRef = useRef(null);
  const isAdmin = auth.hasRoles(['admin']);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <FontAwesomeIcon icon={faUser} />
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 220 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          {/* <Typography variant="subtitle1" noWrap> */}
          {/* displayName */}
          {/* </Typography> */}
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {auth.user.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {isAdmin &&
          MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
              sx={{ typography: 'body2', py: 1, px: 2.5 }}
            >
              <Box
                component={Icon}
                icon={option.icon}
                sx={{
                  mr: 2,
                  width: 24,
                  height: 24
                }}
              />

              {option.label}
            </MenuItem>
          ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={() => auth.logout()}>
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
