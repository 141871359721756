import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import {
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import nl from 'date-fns/locale/nl';
import DatePicker from '@mui/lab/DatePicker';
import { isValid } from 'date-fns';
import { getDate } from '../../utils/date-helpers';
import AddressFields from '../_dashboard/Address/AddressFields';
import { CONTACTSCHEMA } from '../_dashboard/contacts/ContactForm';
import useAuth from '../../hooks/useAuth';
import BranchOrganisations from './BranchOrganisations';

const participantSchema = {
  ...CONTACTSCHEMA,
  birthday: Yup.string().required('Je geboortedatum hebben we nodig voor je certificaat'),
  birthplace: Yup.string().required('Je geboorteplaats hebben we nodig voor je certificaat'),
  phone: Yup.string().required('Telefoonnummer is verplicht')
};

const ParticipantForm = ({ cart, contact, handleBack, onNextHandler }) => {
  const auth = useAuth();
  const [emailExist, setEmailExist] = useState(false);
  const NewParticipantSchema = Yup.object().shape(participantSchema);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      gender: contact?.gender || '',
      firstname: contact?.firstname || '',
      lastname: contact?.lastname || '',
      address: {
        id: contact?.address?.id || null,
        street: contact?.address?.street || '',
        // number: contact?.address?.number || '',
        // numberAddition: contact?.address?.numberAddition || '',
        postcode: contact?.address?.postcode || '',
        city: contact?.address?.city || '',
        country: 'nl'
      },
      birthday: contact?.birthday || '',
      birthplace: contact?.birthplace || '',
      email: contact?.email || '',
      phone: contact?.phone || '',
      type: contact?.type || 'customer',
      companyId: contact?.company?.id || -1,
      branchOrganisations: contact?.brancheOrganisations || []
    },
    validationSchema: NewParticipantSchema,
    onSubmit: async (values, { setErrors }) => {
      if (emailExist) {
        setErrors({ ...errors, email: emailExist });
        return null;
      }

      cart.customer.shipping_address.shipping_gender = values.gender;
      cart.customer.shipping_address.shipping_first_name = values.firstname;
      cart.customer.shipping_address.shipping_last_name = values.lastname;
      cart.customer.shipping_address.shipping_phone = values.phone;
      cart.customer.shipping_address.shipping_email = values.email;
      cart.customer.shipping_address.shipping_date_of_birth = values.birthday;
      cart.customer.shipping_address.birthplace = values.birthplace;

      cart.customer.shipping_address.shipping_address_1 = `${values.address.street}`;
      cart.customer.shipping_address.shipping_address_street = values.address.street;
      // cart.customer.shipping_address.shipping_address_number = values.address.number;
      // cart.customer.shipping_address.shipping_address_number_addition = values.address.numberAddition;
      cart.customer.shipping_address.shipping_city = values.address.city;
      cart.customer.shipping_address.shipping_postcode = values.address.postcode;
      cart.customer.shipping_address.shipping_branches = values.branchOrganisations;

      onNextHandler(values, cart);
      return null;
    }
  });

  const { errors, values, setErrors, touched, setTouched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } =
    formik;
  const birthDateField = getFieldProps('birthday');

  const checkEmail = async () => {
    setTouched({ email: true });
    if (values.email.trim() !== '' && values.email.includes('@') > 0) {
      const canUse = await auth.checkIfEmailIsValid(values.email);
      console.log(canUse);
      if (canUse.error) {
        setErrors({ ...errors, email: canUse.message });
        setEmailExist(canUse.message);
      } else {
        setEmailExist(false);
      }
    }
  };

  const branchChangedHandler = (event) => {
    console.log(event.target.name, event.target.checked);
    const currentBranches = [...values.branchOrganisations];
    if (event.target.checked && !currentBranches.includes(event.target.name)) {
      currentBranches.push(event.target.name);
    }

    if (!event.target.checked && currentBranches.includes(event.target.name)) {
      const index = currentBranches.indexOf(event.target.name);
      if (index > -1) {
        currentBranches.splice(index, 1);
      }
    }

    setFieldValue('branchOrganisations', currentBranches);
  };

  return (
    <FormikProvider value={formik} fullWidth>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Card sx={{ p: 3 }}>
              <Typography textAlign="center" variant="h5" sx={{ mb: 2 }}>
                Deelnemer
              </Typography>
              <Stack spacing={3}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <FormControl fullWidth>
                    <InputLabel id="gender-label">Geslacht</InputLabel>
                    <Select
                      labelId="gender-label"
                      id="gender"
                      label="Geslacht"
                      {...getFieldProps('gender')}
                      error={touched.gender && errors.gender}
                    >
                      <MenuItem value="male">Man</MenuItem>
                      <MenuItem value="female">Vrouw</MenuItem>
                      <MenuItem value="different">Anders</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <TextField
                    fullWidth
                    label="Voornaam"
                    {...getFieldProps('firstname')}
                    error={touched.firstname && errors.firstname}
                    helperText={touched.firstname && errors.firstname}
                  />
                  <TextField
                    fullWidth
                    label="Achternaam"
                    {...getFieldProps('lastname')}
                    error={touched.lastname && errors.lastname}
                    helperText={touched.lastname && errors.lastname}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={nl}>
                    <DatePicker
                      label="Geboortedatum"
                      mask="__-__-____"
                      value={birthDateField.value}
                      onChange={(newValue) => {
                        if (isValid(newValue)) {
                          setFieldValue('birthday', getDate(newValue));
                        }
                      }}
                      onBlur={() => {
                        setTouched('birthday', true);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={touched.birthday && errors.birthday?.length > 0}
                          helperText={touched.birthday && errors.birthday}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <TextField
                    fullWidth
                    label="Geboorteplaats"
                    {...getFieldProps('birthplace')}
                    error={touched.birthplace && errors.birthplace}
                    helperText={touched.birthplace && errors.birthplace}
                  />
                </Stack>
                <Typography variant="subtitle2">Communicatie:</Typography>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <TextField
                    fullWidth
                    label="Telefoonnummer"
                    {...getFieldProps('phone')}
                    error={touched.phone && errors.phone}
                    helperText={touched.phone && errors.phone}
                  />
                  <TextField
                    fullWidth
                    label="E-mailadres"
                    {...getFieldProps('email')}
                    onBlur={checkEmail}
                    error={Boolean(touched.email && errors.email)}
                    helperText={
                      touched.email && errors.email === 'Dit e-mailadres is al in gebruik, login om verder te gaan' ? (
                        <span>
                          Dit e-mailadress is al in gebruik,{' '}
                          <Button
                            onClick={handleBack}
                            variant="text"
                            color="error"
                            size="small"
                            style={{
                              padding: 0,
                              fontWeight: 'bold',
                              textTransform: 'none',
                              display: 'inline-block',
                              lineHeight: 1.5,
                              marginTop: '-2px'
                            }}
                          >
                            klik hier om in te loggen en verder te gaan
                          </Button>
                        </span>
                      ) : (
                        errors.email
                      )
                    }
                  />
                </Stack>
                <Typography variant="subtitle2">Adres:</Typography>
                <AddressFields cammelCase formik={formik} objectName="address" />
                <Typography variant="subtitle2">Brancheorganisaties:</Typography>
                <BranchOrganisations
                  currentBranches={values.branchOrganisations}
                  onBranchChangeHandler={branchChangedHandler}
                />
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 3, sm: 2 }}
                  justifyContent="space-between"
                  sx={{ my: 2 }}
                >
                  <Button variant="text" color="inherit" onClick={handleBack} style={{ marginRight: '1rem' }}>
                    Terug
                  </Button>
                  <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                    Verder
                  </LoadingButton>
                </Stack>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
};

export default ParticipantForm;

ParticipantForm.propTypes = {
  cart: PropTypes.object,
  contact: PropTypes.object,
  handleBack: PropTypes.func,
  onNextHandler: PropTypes.func
};
