import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, TextField, CircularProgress } from '@mui/material';

import { useDispatch, useSelector } from '../../../redux/store';
import { getItems } from '../../../redux/api-actions';
import { companyActions } from '../../../redux/slices/company';
import { generateOptionList } from '../../../utils/lists';

CompanySelect.propTypes = {
  disabled: PropTypes.bool,
  current: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  onChange: PropTypes.func,
  onlyLocations: PropTypes.bool,
  withPrivateOption: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string
};

export default function CompanySelect({
  disabled,
  current,
  onChange,
  onlyLocations,
  withPrivateOption,
  error,
  helperText
}) {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();
  const { items, search, searchChanged, isLoading, perPage, currentPage, order, orderBy } = useSelector(
    (state) => state.companies
  );

  const [options, setOptions] = useState(generateOptionList(items, current));

  useEffect(() => {
    setOptions(generateOptionList(items, current));
  }, [items, current]);

  useEffect(() => {
    if (searchChanged && !isLoading && open) {
      const searchContact = setTimeout(() => {
        dispatch(
          getItems('companies', {
            page: currentPage,
            perPage: 100,
            order,
            orderBy,
            search,
            role: onlyLocations ? 'location' : false
          })
        );
      }, 500);

      return () => {
        clearTimeout(searchContact);
      };
    }
    return true;
  }, [dispatch, currentPage, perPage, order, orderBy, open, search, isLoading, onlyLocations]);

  return (
    <Autocomplete
      fullWidth
      disablePortal
      disabled={disabled}
      id="companySelect"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      options={options}
      value={current}
      getOptionLabel={(option) => option.name}
      onChange={(e, value) => {
        if (value?.id) {
          onChange(value);
        } else {
          onChange(null);
        }
      }}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.name}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={onlyLocations ? 'Locatie' : 'Bedrijf'}
          onChange={(event) => {
            if (event.target.value.length >= 3) {
              dispatch(companyActions.changeSearch(event.target.value));
            }

            if (event.target.value.length === 0) {
              dispatch(companyActions.changeSearch(''));
            }
          }}
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
}
