import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import qs from 'qs';
import { useDispatch } from 'react-redux';
import camelcaseKeys from 'camelcase-keys';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { Typography, Card, OutlinedInput, FormGroup, FormControlLabel, Checkbox, Button, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { numbToEuro } from '../../utils/currency';
import CouponLine from './CouponLine';
import CouponField from './CouponField';
import { newSubscripeLineLabel } from '../../utils/contact';
import { platformSettings } from '../../config';

const axiosLearncareShopping = axios.create({
  withCredentials: true
});

axiosLearncareShopping.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export const subscribe = (cart, cb, errorHandler) => async () => {
  const send = async (cartData) =>
    axiosLearncareShopping.post(
      `${window.lcSubscribe?.url || 'https://learncare.test/wp-admin/admin-ajax.php'}`,
      qs.stringify(cartData)
    );
  try {
    const cartData = {
      action: 'wc_lc_subscribe',
      nonce: window.lcSubscribe?.nonce || '',
      cart
    };

    const response = await send(cartData);
    console.log('response', response);
    const data = camelcaseKeys(response.data, { deep: true });
    cb({ error: false, data });
    return data;
  } catch (e) {
    errorHandler({ message: e.message });
    return false;
  }
};

const StartDateAndLocation = ({ item }) => {
  if (!item?.cart_item_data?.start_and_location?.value) {
    return '';
  }

  return (
    <dl className="variation">
      <dt className="variation-Startdatumenlocatie">{item?.cart_item_data?.start_and_location?.name}:</dt>
      <dd className="variation-Startdatumenlocatie">
        <p>{item?.cart_item_data?.start_and_location?.value}</p>
      </dd>
    </dl>
  );
};

StartDateAndLocation.propTypes = {
  item: PropTypes.object
};

const CartItem = ({ item }) => (
  <tr className="cart_item">
    <td className="product-name">
      {item.name}&nbsp;
      <strong className="product-quantity">×&nbsp;{item.quantity.value}</strong>
      {item.totals.tax === 0 && <sup style={{ opacity: 0.45 }}> *</sup>}
      <StartDateAndLocation item={item} />
    </td>
    <td className="product-total">
      <span className="woocommerce-Price-amount amount">
        <bdi>{numbToEuro((parseFloat(item.totals.subtotal) + parseFloat(item.totals.subtotal_tax)) / 100)}</bdi>
      </span>
      {item.totals.tax > 0 && <small className="tax_label"> (incl. btw)</small>}
    </td>
  </tr>
);

CartItem.propTypes = {
  item: PropTypes.object
};

const ReviewOrder = ({ cart, setLoadingScreen, refreshOrderDetailsHandler, handleBack }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [reviewError, setReviewError] = useState(false);

  const orderDetailsSchema = Yup.object().shape({
    note: Yup.string(),
    privacy: Yup.boolean().required('We hebben toestemming nodig om je gegeven op te verwerken'),
    subscribe: Yup.boolean()
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      note: '',
      privacy: false,
      subscribe: false
    },
    validationSchema: orderDetailsSchema,
    onSubmit: async (values, { setErrors }) => {
      if (!values.privacy) {
        setErrors({ ...errors, privacy: true });
        return null;
      }
      setIsLoading(true);
      cart.customer.shipping_address.shipping_privacy = values.privacy;
      cart.customer.shipping_address.shipping_subscribe = values.subscribe;
      if (values.note !== '') {
        cart.notices = [values.note];
      }

      dispatch(
        subscribe(
          cart,
          (data) => {
            setIsLoading(false);
            if (data?.data?.url) {
              window.location.href = data.data.url;
              return null;
            }

            if (data.error) {
              setReviewError(data.error);
            }
            return null;
          },
          () => {
            setIsLoading(false);
            return null;
          }
        )
      );

      return null;
    }
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  const privacyLineLabel = (
    <span
      dangerouslySetInnerHTML={{
        __html: `Ik ga akkoord met de <a href="https://www.learncare.nl/algemene-voorwaarden/" target="_blank" rel="noreferrer">algemene voorwaarden</a> en ik ben bekend met de <a href="https://learncare.test/privacyverklaring-en-klachtenreglement/" target="_blank" rel="noreferrer">privacy verklaring</a>;`
      }}
    />
  );

  return (
    <Card sx={{ p: 3 }}>
      <Typography textAlign="center" variant="h5" sx={{ mb: 2 }}>
        Bestelling controleren
      </Typography>
      <div className="woocommerce">
        <div id="order_review" className="woocommerce-checkout-review-order">
          <table className="shop_table woocommerce-checkout-review-order-table">
            <thead>
              <tr>
                <th className="product-name">{platformSettings.features.products.title}</th>
                <th className="product-total">Prijs</th>
              </tr>
            </thead>
            <tbody>
              {cart.items.map((item, i) => (
                <CartItem key={i} item={item} />
              ))}
            </tbody>
            <tfoot>
              <tr className="cart-subtotal">
                <th>Subtotaal</th>
                <td>
                  <span className="woocommerce-Price-amount amount">
                    <bdi>
                      {numbToEuro((parseFloat(cart.totals.subtotal) + parseFloat(cart.totals.subtotal_tax)) / 100)}
                    </bdi>
                  </span>{' '}
                  {parseInt(cart.totals.total_tax, 2) > 0 && <small className="tax_label">(incl. btw)</small>}
                </td>
              </tr>
              {cart.coupons.length >= 1 &&
                cart.coupons.map((coupon, i) => (
                  <CouponLine
                    setLoadingScreen={setLoadingScreen}
                    refreshOrderDetailsHandler={refreshOrderDetailsHandler}
                    coupon={coupon}
                    key={i}
                  />
                ))}
              <tr className="order-total">
                <th>Totaal</th>
                <td>
                  <strong>
                    <span className="woocommerce-Price-amount amount">
                      <bdi>{numbToEuro(parseFloat(cart.totals.total) / 100)}</bdi>
                    </span>
                  </strong>{' '}
                  {parseInt(cart.totals.total_tax, 2) > 0 && (
                    <small className="includes_tax">
                      (inclusief{' '}
                      <span className="woocommerce-Price-amount amount">
                        {numbToEuro(parseFloat(cart.totals.total_tax) / 100)}
                      </span>{' '}
                      BTW 21%)
                    </small>
                  )}
                </td>
              </tr>
              {parseInt(cart.totals.total_tax, 2) === 0 && (
                <td colSpan={2} style={{ textAlign: 'right', paddingBottom: '1rem' }}>
                  <small className="includes_tax">* vrijgesteld van btw</small>
                </td>
              )}
            </tfoot>
          </table>
        </div>
      </div>
      <CouponField setLoadingScreen={setLoadingScreen} refreshOrderDetailsHandler={refreshOrderDetailsHandler} />
      <FormikProvider value={formik} fullWidth>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <OutlinedInput
            fullWidth
            multiline
            row={4}
            {...getFieldProps('note')}
            error={touched.note && errors.note}
            helperText={touched.note && errors.note}
            placeholder="Opmerking"
          />

          <FormGroup>
            <Typography color={errors.privacy ? 'error' : 'body'} sx={{ my: 2 }}>
              Hierbij geef ik toestemming voor het verwerken van mijn persoonsgegevens door Learncare Academy voor:
            </Typography>
            <FormControlLabel
              control={<Checkbox {...getFieldProps('privacy')} />}
              label={privacyLineLabel}
              color={errors.privacy ? 'error' : 'body'}
            />
            <FormControlLabel control={<Checkbox {...getFieldProps('subscribe')} />} label={newSubscripeLineLabel} />
          </FormGroup>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 3, sm: 2 }}
            justifyContent="space-between"
            sx={{ my: 2 }}
          >
            <Button variant="text" color="inherit" onClick={handleBack} style={{ marginRight: '1rem' }}>
              Terug
            </Button>
            {reviewError && <Typography color="error">{reviewError}</Typography>}
            {!reviewError && (
              <LoadingButton type="submit" variant="contained" disabled={isLoading} loading={isLoading}>
                Verder
              </LoadingButton>
            )}
          </Stack>
        </Form>
      </FormikProvider>
    </Card>
  );
};

export default ReviewOrder;

ReviewOrder.propTypes = {
  cart: PropTypes.object,
  handleBack: PropTypes.func,
  company: PropTypes.object,
  contact: PropTypes.object
};
