import React, { useState } from 'react';
import { Box, FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox, FormHelperText } from '@mui/material';

export const DEFAULT_BRANCHES = [
  { value: 'horus', label: 'Horus' },
  { value: 'kiwa', label: 'KIWA' },
  { value: 'nvvbs', label: 'NVVBS' },
  { value: 'wsnp', label: 'WSNP' }
];

const BranchOrganisations = ({ currentBranches, onBranchChangeHandler, displayLabel }) => (
  <FormControl component="fieldset" variant="standard" style={{ marginTop: 0 }}>
    {!displayLabel && (
      <FormLabel component="legend" focused={false}>
        Vink aan van welke brancheverenigingen je lid bent. Door dit aan te geven zie je in het account van Learncare
        hoeveel PE-punten je per training hebt behaald.
      </FormLabel>
    )}
    <FormGroup row>
      {DEFAULT_BRANCHES.map((branch) => (
        <FormControlLabel
          key={branch.value}
          control={
            <Checkbox
              checked={currentBranches.includes(branch.value)}
              onChange={onBranchChangeHandler}
              name={branch.value}
            />
          }
          label={branch.label}
        />
      ))}
    </FormGroup>
  </FormControl>
);

export default BranchOrganisations;
