import React from 'react';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { Button, Stack, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { verify2Fa } from '../api';
import useAuth from '../../hooks/useAuth';

const verifyCode2faSchema = Yup.object().shape({
  one_time_password: Yup.string().required('Two factor code is required')
});

const VerifyCodeForm = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();
  const formik = useFormik({
    initialValues: {
      one_time_password: ''
    },
    validationSchema: verifyCode2faSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        dispatch(
          verify2Fa(
            { one_time_password: values.one_time_password },
            (data) => {
              console.log('verify2Fa', data);
              resetForm();
              enqueueSnackbar(data?.data?.message, { variant: 'success' });
              auth.revalidate();
            },
            (error) => {
              enqueueSnackbar(error?.message ?? 'Something is wrong', { variant: 'error' });
            }
          )
        );
      } catch (error) {
        console.log('login onsubmit error: ', error.response.data);
        setSubmitting(false);
        setErrors(error?.response?.data?.errors);
      }
    }
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (
    <FormikProvider value={formik} fullWidth>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Stack direction="column" gap={2}>
          <TextField
            fullWidth
            size="small"
            label="Code"
            {...getFieldProps('one_time_password')}
            error={Boolean(touched.one_time_password && errors.one_time_password)}
            helperText={touched.one_time_password && errors.one_time_password}
          />
          <Button type="submit" variant="contained" size="medium">
            Versturen
          </Button>
        </Stack>
      </Form>
    </FormikProvider>
  );
};

export default VerifyCodeForm;
