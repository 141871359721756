// import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import { defaultInitialReducers, defaultInitialState } from '../defaults';

// ----------------------------------------------------------------------

const slice = createSlice({
  name: 'invoice',
  initialState: { ...defaultInitialState, orderBy: 'invoice_date', order: 'desc', filterByStatus: '' },
  reducers: {
    ...defaultInitialReducers,
    setFilterByStatus(state, action) {
      console.log('invoice slice', action.payload);
      state.filterByStatus = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const invoiceActions = slice.actions;
