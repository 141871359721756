import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/authentication/Login';
import VerifyCode2fa from '../2fa/pages/VerifyCode2fa';
import { PATH_AUTH, PATH_DASHBOARD } from '../routes/paths';
import VerifyIP from '../pages/authentication/VerifyIP';

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default function AuthGuard({ children }) {
  const { isAuthenticated, need2FA, user, needIpCheck } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (isAuthenticated && user && user?.forceMfa && PATH_DASHBOARD.general.twoFactorConfig !== pathname) {
    return <Navigate to={PATH_DASHBOARD.general.twoFactorConfig} />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }

    if (need2FA) {
      return <VerifyCode2fa />;
    }

    if (needIpCheck) {
      return <Navigate to={PATH_AUTH.verifyIp} />;
    }

    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
