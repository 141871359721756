import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { coCartApiActions } from './co-cart-api';

// const CoCartCoupon = new CoCartAPI({
//   url: window.lcSubscribe?.cartUrl || 'https://learncare.test',
//   version: 'cocart/v1'
//   // consumerKey: 'ck_cfcfd18fcf965ac31c294a1ff1ab4537e1941799',
//   // consumerSecret: 'cs_a25a67996ddff3c8b3286c249ab8e5f4a61b83e6'
// });

const CouponField = ({ refreshOrderDetailsHandler }) => {
  const [coupon, setCoupon] = useState('');
  const [couponError, setCouponError] = useState('');
  const [applyingCoupon, setApplyingCoupon] = useState(false);
  const dispatch = useDispatch();

  const applyCoupon = () => {
    if (!coupon) {
      setCouponError('Vul een geldige waardebon in');
      return null;
    }
    setApplyingCoupon(true);
    dispatch(
      coCartApiActions(
        'add',
        coupon,
        (response) => {
          setApplyingCoupon(false);
          if (response.cart_hash) {
            refreshOrderDetailsHandler(response);
          }
        },
        (error) => {
          console.log('CouponFieldError', error);
          if (error.message === 'Request failed with status code 500') {
            setCouponError('Waardebon ongeldig');
          } else {
            setCouponError(error.message);
          }
          setApplyingCoupon(false);
        }
      )
    );
  };

  return (
    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }} sx={{ marginBottom: 2 }}>
      <TextField
        fullWidth
        label="Waardebon"
        value={coupon}
        onChange={(e) => {
          if (couponError) {
            setCouponError(false);
          }
          setCoupon(e.target.value);
        }}
        error={couponError}
        helperText={couponError}
      />
      <LoadingButton type="button" onClick={applyCoupon} variant="contained" loading={applyingCoupon}>
        Toevoegen
      </LoadingButton>
    </Stack>
  );
};

export default CouponField;
