import axios from 'axios';
import { sanctumConfig } from '../config';

// ----------------------------------------------------------------------

const axiosLearncare = axios.create({
  baseURL: sanctumConfig.baseUrl,
  withCredentials: true
});

axiosLearncare.defaults.withCredentials = true;
axiosLearncare.defaults.headers.Accept = 'application/json';

axiosLearncare.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.data?.code === 'need_2fa_config') {
      return Promise.reject(error.response.data);
    }

    if (error.message === 'canceled') {
      return Promise.reject(error.message);
    }
    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export default axiosLearncare;
