// scroll bar
import 'simplebar/src/simplebar.css';

// highlight
import './utils/highlight';

import 'react-quill/dist/quill.snow.css';

import ReactDOM from 'react-dom';
import { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// Store
import { Provider as ReduxProvider } from 'react-redux';
import { store } from './redux/store';

// contexts
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';

//
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { SanctumProvider } from './contexts/SanctumContext';
import SubscribeForm from './SubscribeForm';
// import { AuthProvider } from './contexts/JWTContext';

// ----------------------------------------------------------------------

let loadSubscribeForm = true;
let loadApp = false;
const loadAppOn = document.getElementById('subscribeForm');
if (!loadAppOn) {
  loadApp = true;
  loadSubscribeForm = false;
}

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <ReduxProvider store={store}>
        <SettingsProvider>
          <CollapseDrawerProvider>
            <BrowserRouter>
              <SanctumProvider>
                {loadSubscribeForm && <SubscribeForm />}
                {loadApp && <App />}
              </SanctumProvider>
            </BrowserRouter>
          </CollapseDrawerProvider>
        </SettingsProvider>
      </ReduxProvider>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById(loadSubscribeForm ? 'subscribeForm' : 'root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
