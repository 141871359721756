import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import { PATH_ACCOUNT, PATH_DASHBOARD, PATH_AUTH } from '../routes/paths';
import VerifyCode2fa from '../2fa/pages/VerifyCode2fa';
import VerifyIP from '../pages/authentication/VerifyIP';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default function GuestGuard({ children }) {
  const auth = useAuth();
  const { isAuthenticated, need2FA, needIpCheck } = useAuth();

  console.log(needIpCheck);

  const isAdminOrTeacher = auth.hasRoles(['admin', 'teacher', 'coach']);
  if (!isAuthenticated && need2FA) {
    return <VerifyCode2fa />;
  }

  if (!isAuthenticated && needIpCheck) {
    return <VerifyIP />;
  }

  if (isAuthenticated && isAdminOrTeacher) {
    return <Navigate to={PATH_DASHBOARD.general.dashboard} />;
  }

  if (isAuthenticated && !isAdminOrTeacher) {
    return <Navigate to={PATH_ACCOUNT.general.orders} />;
  }

  return <>{children}</>;
}
