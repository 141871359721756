import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { csrf } from '../../utils/sanctum';
import cacheProvider from '../cacheProvider';
import { defaultInitialReducers, defaultInitialState } from '../defaults';

// ----------------------------------------------------------------------

const slice = createSlice({
  name: 'schedule',
  initialState: {
    ...defaultInitialState,
    orderBy: 'startdate',
    isOpen: false,
    selectSchedule: {}
  },
  reducers: {
    ...defaultInitialReducers,
    selectSchedule(state, action) {
      state.isOpen = true;
      state.selectSchedule = { ...action.payload };
    },
    schedulePresentChanged(state, action) {
      const selectSchedule = { ...state.selectSchedule };
      const presentIdex = selectSchedule.presents.findIndex((present) => present.id === action.payload.id);
      if (presentIdex >= 0) {
        selectSchedule.presents[presentIdex] = action.payload;
      } else {
        selectSchedule.presents.push(action.payload);
      }
      state.selectSchedule = selectSchedule;
      state.selectSchedule.isChanged = true;
    },
    closeHandler(state) {
      state.isOpen = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const scheduleActions = slice.actions;

export const getSchedules = () => async (dispatch) => {
  const fetchData = async () => {
    await csrf();
    return axios.get(`/api/schedules`);
  };

  const data = cacheProvider('schedules', false);
  if (data && data.meta > 0) {
    dispatch(slice.actions.getSchedulesSuccess(data));
    return;
  }

  try {
    dispatch(slice.actions.startLoading());
    const response = await fetchData();
    cacheProvider('schedules', response.data);
    dispatch(slice.actions.getSchedulesSuccess(response.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

export const getScheduleById = (id, cb) => async () => {
  const getSchedule = async () => axios.get(`/api/schedule/${id}`);
  try {
    const response = await getSchedule();
    cb(response.data);
    return response.data;
  } catch (e) {
    return false;
  }
};
