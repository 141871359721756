import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';
import { platformSettings } from '../config';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return <Box sx={{ width: '100%', maxWidth: '100px', ...sx }}>{platformSettings.logo}</Box>;
}
