import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { TextField, Alert, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hooks
import { sendPasswordReset } from '../../../redux/api-actions';
import { useDispatch } from '../../../redux/store';

// ----------------------------------------------------------------------

ResetPasswordForm.propTypes = {
  onSent: PropTypes.func,
  onGetEmail: PropTypes.func
};

export default function ResetPasswordForm({ onSent, onGetEmail, onIsMulti }) {
  const dispatch = useDispatch();

  const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required')
  });

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: ResetPasswordSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      dispatch(
        sendPasswordReset(
          values.email,
          (data) => {
            setSubmitting(false);
            if (data.data === 'passwords.sent') {
              onGetEmail(values.email);
              onSent();
              return;
            }

            if (data.data === 'passwords.sent.multi') {
              onGetEmail(values.email);
              onSent();
              onIsMulti();
              return;
            }

            if (data.data === 'passwords.throttled') {
              setErrors({ afterSubmit: 'Probeer het opnieuw na 1 minute' });
              return;
            }

            if (data.data === 'passwords.user') {
              setErrors({ afterSubmit: 'E-mailadres onjuist' });
              return;
            }

            setErrors({ afterSubmit: 'Er is iets fout gegaan' });
          },
          (error) => {
            console.log('error', error);
            setSubmitting(false);
            setErrors({ afterSubmit: error.message });
            setSubmitting(false);
          }
        )
      );
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

          <TextField
            fullWidth
            {...getFieldProps('email')}
            type="email"
            placeholder="email@email.nl"
            label="e-mailadres"
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            Wachtwoord reset
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
