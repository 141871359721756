import React from 'react';
import PropTypes from 'prop-types';
import { Stack, TextField } from '@mui/material';

const AddressFields = ({ formik, objectName }) => {
  const { errors, touched, getFieldProps } = formik;

  return (
    <>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
        <TextField
          fullWidth
          label="Straat en Huisnr"
          {...getFieldProps(`${objectName}.street`)}
          error={touched && !!touched[objectName]?.street && errors && !!errors[objectName]?.street}
          helperText={touched && !!touched[objectName]?.street && errors && errors[objectName]?.street}
        />
      </Stack>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
        <TextField
          fullWidth
          label="Postcode"
          {...getFieldProps(`${objectName}.postcode`)}
          error={touched && !!touched[objectName]?.postcode && errors && !!errors[objectName]?.postcode}
          helperText={touched && !!touched[objectName]?.postcode && errors && errors[objectName]?.postcode}
        />
        <TextField
          fullWidth
          label="Plaats"
          {...getFieldProps(`${objectName}.city`)}
          error={touched && !!touched[objectName]?.city && errors && !!errors[objectName]?.city}
          helperText={touched && !!touched[objectName]?.city && errors && errors[objectName]?.city}
        />
      </Stack>
    </>
  );
};

export default AddressFields;

AddressFields.propTypes = {
  formik: PropTypes.object,
  objectName: PropTypes.string,
  cammelCase: PropTypes.bool
};
